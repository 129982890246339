import React from "react";

import icon from '../../../assets/images/EventWhistle.svg';

const Kickoff = () => {

	return (
		<div className="event kickoff milestone">
			<div className="icon">
				<img src={ icon } alt="Pfft Pfft Pffffffffffft"/>
			</div>
			<div className="info">Kickoff</div>
		</div>
	);
};

// Should component update
export default React.memo(Kickoff, () => {
	return true;
});