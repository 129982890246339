import React from 'react';

// Using the same markup and css as a regular fixture
import '../../../assets/scss/components/team/Fixture.scss';
import '../../../assets/scss/components/archive/fixtures/Fixture.scss';

import { translateCompetitionName } from '../../../utils/helpers';
import { NavLink } from 'react-router-dom';

const Fixture = (props) => {

	const { gameInfo, homeTeam, awayTeam } = { ...props }

	return (
		<div className="fixture archive-fixture">
			<div className="round">{ `${ translateCompetitionName(gameInfo.competition.name) }, round ${ gameInfo.competition.round }, season ${ gameInfo.season }` }</div>
			<div className="teams">
				<div className="team team-home">{ homeTeam.name }</div>
				<div className="score"><span>{ homeTeam.goals }</span><span className="seperator" /><span>{ awayTeam.goals }</span></div>
				<div className="team team-away">{ awayTeam.name }</div>
			</div>
			<NavLink
				to={ `/archive/fixtures/${ gameInfo.match_id }/` }
				className="match-report-link"
			>
				<span>View matchreport</span>
			</NavLink>
		</div>
	);
};

// Never re-render
export default React.memo(Fixture, () => {
	return true;
});