import React from 'react';

import { NavLink } from 'react-router-dom';

const Logo = () => {

	return (
		<NavLink to="/" className="logo">
			<span className="copy">SMRPG</span>
			<span className="tagline">Season <span>12</span></span>
		</NavLink>
	);
}

// Never re-render
export default React.memo(Logo, (prevProps, nextProps) => {
	return true;
});