import React from "react";

import icon from '../../../assets/images/EventGoal.svg';

const Goal = (props) => {

	const data = props.data;
	const className = `event goal ${ props.isHomeTeam ? 'home-team' : 'away-team' }`;
	const score = props.score;

	return (
		<div className={ className }>
			<div className="icon">
				<img src={ icon } alt="Goal"/>
			</div>
			<div className="minute">{ data.minute }'</div>
			<div className="details">
				<div className="label">GOAL { score.home } - { score.away }</div>
				<div className="player1">{ data.playerInvolved1 }</div>
				{(() => {
					if(data.playerInvolved2) {
						return (
							<div className="player2">({ data.playerInvolved2 })</div>
						);
					}
				})()}
			</div>
		</div>
	);
};

export default React.memo(Goal, (prevProps, nextProps) => {

	return prevProps.isHomeTeam === nextProps.isHomeTeam
		&& prevProps.data.minute === nextProps.data.minute
		&& prevProps.data.playerInvolved1 === nextProps.data.playerInvolved1
		&& prevProps.data.playerInvolved2 === nextProps.data.playerInvolved2
		&& prevProps.score.home === nextProps.score.home
		&& prevProps.score.away === nextProps.score.away;
});