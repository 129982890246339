import React from 'react';

import '../../assets/scss/components/tactics/HiddenPlayerPositions.scss';

const HiddenPlayerPositions = () => {

	return (
		<div className="hidden-player-positions">
			<div className="hidden-player-positions-inner">
				<a href="https://www.youtube.com/watch?v=qAkZT_4vL_Y" target="_blank" rel="noreferrer nofollow">
				What's he building in there?<br />
				What the hell is he building in there?<br />
				We have a right to know!
				</a>
			</div>
		</div>
	);

};

// Should component update
export default React.memo(HiddenPlayerPositions, (prevProps, nextProps) => {
	return true;
});