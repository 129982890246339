import Fixture from './Fixture';

import '../../assets/scss/components/team/Fixtures.scss';

const Fixtures = (props) => {

	const teamName = props.teamName; // Name of the Team
	const fixtures = props.fixtures;

	return (
		<div className="fixtures">
			<div className="fixture-list">
				{
					fixtures.map((fixture, index) => {
						const key = `game${index}`;

						return <Fixture
							key={ key }
							teamName={ teamName }
							data={ fixture }
						/>
					})
				}
			</div>
		</div>
	);
};

export default Fixtures;