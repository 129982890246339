import React from 'react';

import iconManager from '../../assets/images/Manager.svg';
import IconAndValue from '../layout/IconAndValue';

const Manager = (props) => {

	const { name } = { ...props };

	return (
		<IconAndValue
			label="Manager"
			className="manager"
			icon={ iconManager }
			name={ name }
		/>
	);
}

// Never re-render
export default React.memo(Manager, (prevProps, nextProps) => {
	return prevProps.name === nextProps.name;
});