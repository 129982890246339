import React, { useState, useEffect } from "react";

import '../../assets/scss/components/dice/Die.scss';

import styled from "styled-components";

import { invertColor, leadingZero } from '../../utils/helpers';

const StyledDie = styled.div`
	.face {
		border-bottom-color: ${ props => props.$colors.color1 ? props.$colors.color1 : '#000' };
	
		&:before {
			color: ${ props => props.$colors.color2 ? props.$colors.color2 === props.$colors.color1 ? invertColor(props.$colors.color2) : props.$colors.color2 : '#FFF' };
		}
		&:after {
			border-top-color: ${ props => props.$colors.color1 ? props.$colors.color1 : '#000' };
		}
	}`;

const Die = (props) => {

	const [dieDefaultAnimation, setDieDefaultAnimation] = useState(true);
	const { face, roll_id, power, colors } = { ...props };

	// Change the way the animation is working
	useEffect(() => {
		setDieDefaultAnimation(!dieDefaultAnimation); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roll_id]);

	const values = ( power === 'ones' ?
		Array.from({length: 10}, (_, i) => i) :
		Array.from({length: 10}, (_, i) => i * 10)
	);

	return (
		<StyledDie
			className="die"
			$colors={ colors }
			data-power={ power }
			data-face={ face }
			data-default-animation={ dieDefaultAnimation }
		>
			<div className="die-inner">
				{
					values.map((value, i) => {

						const className = `face face-${i}`;

						return (<figure
							key={ `die-${power}-${value}` }
							className={ className }
							data-face={ power === 'tens' ? leadingZero(value) : value }
						/>
						);
					})
				}
			</div>
		</StyledDie>
	);
};

// Never re-render if the roll_id hasn't changed
export default React.memo(Die, (prevProps, nextProps) => {
	return prevProps.roll_id === nextProps.roll_id
		&& prevProps.colors.color1 === nextProps.colors.color1
		&& prevProps.colors.color2 === nextProps.colors.color2;
});