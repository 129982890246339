import React from 'react';

import pitchIcon from '../../assets/images/Pitch.png';

const Pitch = () => {

	return (
		<figure className="pitch">
			<img src={ pitchIcon } alt=""/>
		</figure>
	);
};

export default React.memo(Pitch, () => {
	return true;
});