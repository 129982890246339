import React, { useEffect, useState } from 'react';
import { db } from '../../services/Firebase';

import '../../assets/scss/components/team/LeaguePosition.scss';

import TableHeader from '../tables/TableHeader';
import TableTeam from '../tables/TableTeam';
import {onValue, query, ref} from "firebase/database";

const LeaguePosition = (props) => {

	const { league, slug } = { ...props };

	const [data, setData] = useState({
		loading: true,
		teams: {},
	});

	// Get Teams on "componentDidMount" and remove event listener on componentDidUnmount
	useEffect(() => {

		const dbRef = ref(db, `tables/${league}/`);
		const queryTable = query(dbRef);

		const unsubscribe = onValue(queryTable, (snapshot) => {

			const teams = {}

			snapshot.forEach((snap) => {
				teams[snap.key] = snap.val();
			});

			setData({
				loading: false,
				teams: teams,
			});
		});

		return () => {
			// A callback that can invoked to remove a listener.
			unsubscribe();
		}
	}, [league]);


	// Find the index in the teams object containing all the teams
	const index = Object.keys(data.teams).findIndex((key, i) => {
		return data.teams[key].team.slug === slug;
	});

	// Get the other teams around the team
	const teamsToShow = {};
	Object.keys(data.teams).forEach((key, i) => {

		if(index === 0) {
			if(i <= 4) {
				teamsToShow[i] = data.teams[key];
			}
		}
		if(index === 1) {
			if(i >= 0 && i <= index + 3) {
				teamsToShow[i] = data.teams[key];
			}
		}
		else if(index === 8) {
			if(i >= 5) {
				teamsToShow[i] = data.teams[key];
			}
		}
		else if(index === 9) {
			if(i >= 5) {
				teamsToShow[i] = data.teams[key];
			}
		}
		else {
			if(i >= index - 2 && i <= index + 2) {
				teamsToShow[i] = data.teams[key];
			}
		}
	});

	return (
		<div
			className="league-position table"
			data-loading={ data.loading }
		>
			<TableHeader
				limit={ 3 }
			/>

			{(() => {
				if(data.loading) {
					return [...Array(5)].map((_, i) => {
						return <TableTeam
							key={ i }
							data={{ position: i } }
						/>
					});
				}
				else {
					return Object.keys(teamsToShow).map((key) => {
						return <TableTeam
							key={ key }
							data={ teamsToShow[key] }
							highlight={ teamsToShow[key].team.slug === slug }
							limit={ 3 }
						/>
					});
				}
			})()}

		</div>
	);
};

export default React.memo(LeaguePosition, (prevProps, nextProps) => {
	return prevProps.slug === nextProps.slug;
});