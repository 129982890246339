import React from 'react';
import Team from './Team';

const TeamsList = (props) => {

	const teams = props.teams;

	return (
		<nav className="teams">
			<ul>
			{
				Object.keys(teams).map((key) => {

					return (
						<li key={ key }>
							<Team
								data={ teams[key] }
							/>
						</li>
					);

				})
			}
			</ul>
		</nav>
	);
};

// Never re-render
export default React.memo(TeamsList, (prevProps, nextProps) => {
	return true;
});