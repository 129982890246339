import React, { useState, useEffect } from "react";

import { ref, set, query, onValue, orderByChild, equalTo, limitToLast } from "firebase/database";
import { auth, db } from '../../services/Firebase';

import '../../assets/scss/components/dice/TeamDice.scss';
import { getRandomRoll, getUserIdFromTeamSlug, isGameMaster } from '../../utils/helpers';

import Die from './Die';
import RollButton from './RollButton';
import Log from './Log';

const TeamDice = ({ slug, name, kitId, limit }) => {

	const userId = getUserIdFromTeamSlug(slug); // userId who has access to this team

	const [data, setData] = useState({
		loading: true,
		rolls: [],
	});

	// Get Rolls on "componentDidMount" and remove eventlistener on componentDidUnmount
	useEffect(() => {

		const dbRef = ref(db, "dice/rolls/");
		const queryConstraints = [orderByChild("uid"), equalTo(userId), limitToLast(limit || 10)];
		const queryRolls = query(dbRef, ...queryConstraints);

		const unsubscribe = onValue(queryRolls, (snapshot) => {

			const rolls = [];
			snapshot.forEach((snap) => {
				rolls.push( snap.val() );
			});
			rolls.reverse();

			setData({
				loading: false,
				rolls: rolls,
			});
		});

		return () => {
			// A callback that can invoked to remove a listener.
			unsubscribe();
		}
	}, [userId, kitId, limit]);

	// Handle new Rolls
	const handleRoll = (hidden, teamSlug) => {

		// Get the User logged in
		const user = auth.currentUser;

		// If no user is logged in then we can't continue
		if(!user) {
			alert('You need to login...');
			return false;
		}

		// If the User and team's User doesn't match, then we can't continue
		const userIdTeam = getUserIdFromTeamSlug(teamSlug);
		if(userIdTeam !== user.uid) {
			alert('You are not the owner of ' + teamSlug);
			return false;
		}

		const uid = user.uid;
		const roll = getRandomRoll();
		const now = Date.now();
		const roll_id = `roll-${now}-${teamSlug}`;

		set(ref(db, `dice/rolls/${roll_id}`), {
			roll,
			roll_id,
			uid,
			now,
			hidden,
			team_slug: teamSlug,
		}).then(_ => {
			//console.log('handleRoll SUCCESS', hidden, teamSlug, roll_id);
		}).catch((error) => {
			// The write failed...
			//console.log('handleRoll ERROR', error);
		});
	}

	// Getting and setting the Dice colors
	const [diceColors, setDiceColors] = useState({
		color1: '#FFF',
		color2: '#000',
	});

	// Get Teams on "componentDidMount" and remove eventlistener on componentDidUnmount
	useEffect(() => {

		const dbRef = ref(db, `teams/${slug}/team/data/dice/${( kitId === '3rd' ? 'kit3' : kitId === '2nd' ? 'kit2' : 'kit1' )}`);
		const unsubscribe = onValue(dbRef, (snapshot) => {

			let color1 = '#000';
			let color2 = '#FFF';

			snapshot.forEach((snap) => {
				if(snap.key === 'color1') {
					color1 = snap.val();
				}
				if(snap.key === 'color2') {
					color2 = snap.val();
				}
			});

			setDiceColors({
				color1,
				color2,
			});
		});

		return () => {
			// A callback that can invoked to remove a listener.
			unsubscribe();
		}
	}, [slug, kitId]);

	if(data.loading) {
		return (
			<div className="team-dice">
				<div className="dice-wrapper">
					<div className="loading">Finding my lucky dice...</div>
				</div>
			</div>
		);
	}

	const rolls = data.rolls;
	const latestRoll = ( rolls[0] ? rolls[0] : null );
	const roll_id = ( latestRoll ? latestRoll.roll_id : null );

	let value = ( latestRoll ? latestRoll.roll : null );

	// Always allow the loggedIn Game Master to see the roll
	// Allow FCB and Wol. to see each others rolls
	if(!isGameMaster() && latestRoll) {
		if(latestRoll.hidden) {
			value = null;
		}
	}

	let faceDieTens = ( !value ? '??' : Math.floor(value / 10) );
	faceDieTens = ( faceDieTens >= 10 ? 0 : faceDieTens );
	const faceDieOnes = ( !value ? '?' : value % 10 );

	return (
		<div className="team-dice">
			<div className="dice-wrapper">
				<Die
					roll_id={ roll_id }
					face={ faceDieTens }
					power="tens"
					colors={ diceColors }
				/>
				<Die
					roll_id={ roll_id }
					face={ faceDieOnes }
					power="ones"
					colors={ diceColors }
				/>
			</div>

			<RollButton
				colors={ diceColors }
				slug={ slug }
				makeRoll={ handleRoll }
			/>
			<Log
				rolls={ rolls }
				name={ name }
			/>

		</div>
	);
};

// Never re-render if the roll_id hasn't changed
export default React.memo(TeamDice, (prevProps, nextProps) => {
	return prevProps.roll_id === nextProps.roll_id;
});