import React from "react";
import { NavLink } from "react-router-dom";

import '../../assets/scss/components/layout/MenuPrimary.scss';

const MenuPrimary = () => {

	const handleClick = (e) => {
		document.body.classList.remove('js-primary-menu-revealed');
	};

	const activeClassName = 'js-active';

	//console.log('MenuPrimary.render()');

	return (
		<nav className="menu-primary">
			<ul className="menu">
				<li className="menu-item"><NavLink to="/teams/" className={({ isActive }) => isActive ? activeClassName : ''} onClick={handleClick}>Teams</NavLink></li>
				<li className="menu-item"><NavLink to="/tables/" className={({ isActive }) => isActive ? activeClassName : ''} onClick={handleClick}>Tables</NavLink></li>
				<li className="menu-item"><NavLink to="/match-centre/" className={({ isActive }) => isActive ? activeClassName : ''} onClick={handleClick}>Match Centre 1</NavLink></li>
				<li className="menu-item"><NavLink to="/match-centre-2/" className={({ isActive }) => isActive ? activeClassName : ''} onClick={handleClick}>Match Centre 2</NavLink></li>
				<li className="menu-item"><NavLink to="/archive/fixtures/" className={({ isActive }) => isActive ? activeClassName : ''} onClick={handleClick}>Archive</NavLink></li>
				<li className="menu-item"><NavLink to="/dev/kit-designer/" className={({ isActive }) => isActive ? activeClassName : ''} onClick={handleClick}>Kit Designer</NavLink></li>
				<li className="menu-item"><NavLink to="/dev/todo/" className={({ isActive }) => isActive ? activeClassName : ''} onClick={handleClick}>Todo</NavLink></li>
			</ul>
		</nav>
	);
};

// Never re-render
export default React.memo(MenuPrimary, (prevProps, nextProps) => {
	return true;
});