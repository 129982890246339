import React from 'react';

import IconAndValue from '../layout/IconAndValue';
import iconTactic from '../../assets/images/Tactic.svg';

const Tactic = (props) => {

	const { data } = { ...props };

	return (
		<IconAndValue
			label={`Tactic`}
			className="tactic"
			icon={ iconTactic }
			name={ `Counter ${data.counter }` }
		/>
	);
}

// Never re-render
export default React.memo(Tactic, (prevProps, nextProps) => {
	return prevProps.data.counter === nextProps.data.counter;
});