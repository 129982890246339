import React from 'react';

import '../../assets/scss/components/match-centre/TeamStats.scss';
import TeamStat from './TeamStat';
import { isGameMaster, isHumanTeam } from '../../utils/helpers';
import { auth } from '../../services/Firebase';

const TeamStats = (props) => {

	// Props
	const { slug, stats } = { ...props };
	const { gk, def, mid, att, team_rating, form, tactic} = { ...stats };

	const user = auth.currentUser;

	const hideValue = ( !user
		? true
		: (isGameMaster() ? false : !isHumanTeam(slug))
	);

	return (
	<div className="team-stats">
		<ul className="stats">
			<TeamStat
				label="GK"
				value={ gk }
				hideValue={ hideValue }
			/>
			<TeamStat
				label="D"
				value={ def }
				hideValue={ hideValue }
			/>
			<TeamStat
				label="M"
				value={ mid }
				hideValue={ hideValue }
			/>
			<TeamStat
				label="A"
				value={ att }
				hideValue={ hideValue }
			/>
			<TeamStat
				label="Team"
				value={ team_rating }
				hideValue={ hideValue }
			/>
			<TeamStat
				label="Form"
				value={ form }
				hideValue={ hideValue }
			/>
			<TeamStat
				label="Tac"
				value={ tactic }
				hideValue={ hideValue }
			/>
		</ul>
	</div>);

};
export default TeamStats;