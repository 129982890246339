import React from "react";

import Players from './Players';
import TeamInfo from './TeamInfo';
import TeamKit from './TeamKit';
import TeamDice from '../dice/TeamDice';
import TacticBoard from '../tactics/TacticBoard';
import TeamStats from './TeamStats';

const Team = (props) => {

	const { teamData, players, isHomeTeam } = { ...props };

	return (
		<div className="team">
			<TeamKit
				slug={ teamData.slug }
				kitId={ teamData.kitId }
			/>
			<TeamStats
				slug={ teamData.slug }
				stats={ teamData.stats }
			/>
			<TeamDice
				slug={ teamData.slug }
				name={ teamData.name }
				kitId={ teamData.kitId }
				limit={ 5 }
			/>
			<TacticBoard
				slug={ teamData.slug }
				players={ players }
				kitId={ teamData.kitId }
				isHomeTeam={ isHomeTeam }
			/>
			<TeamInfo
				teamInfo={ teamData }
			/>
			<Players
				players={ players }
			/>
		</div>
	);

};
export default Team;