import React, { useEffect, useState } from 'react';
import { onValue, orderByChild, query, ref} from "firebase/database";
import { db } from '../../../services/Firebase';

import '../../../assets/scss/components/dev/TodoPage.scss';

const TodoPage = () => {

	const [data, setData] = useState({
		loading: true,
		list: {},
	});

	// Get Teams on "componentDidMount" and remove eventlistener on componentDidUnmount
	useEffect(() => {

		const dbRef = ref(db, "global-data/todo/");
		const queryConstraints = [orderByChild('category')];
		const queryTodos = query(dbRef, ...queryConstraints);

		const unsubscribe = onValue(queryTodos, (snapshot) => {

			const list = {};

			snapshot.forEach((snap) => {

				const val = snap.val();

				if(!list[val.category]) {
					list[val.category] = {};
				}
				list[val.category][snap.key] = val;
			});

			setData({
				loading: false,
				list: list,
			});
		});

		return () => {
			// A callback that can invoked to remove a listener.
			unsubscribe();
		}
	}, [data.loading]);

	if(data.loading) {
		return (
			<div className="page todo-page">
				<h1 className="page-title">ToDo + Nice to have</h1>
				<div className="loading">LOADING</div>
			</div>
		);
	}

	return (
		<div className="page todo-page">
			<h1 className="page-title">ToDo + Nice to have</h1>

			{
				Object.keys(data.list).map((category, index) => {

					const listInCat = data.list[category];

					if(Object.keys(listInCat).length > 0) {
						return (
							<div
								key={ `category-${index}` }
								className="category"
							>
								<h3>{ category }</h3>
								<ul>
									{
										Object.keys(listInCat).map((key) => {
											return <li key={ key }>
												{ listInCat[key].what } (<strong>{listInCat[key].who}</strong>)
											</li>
										})
									}
								</ul>
								<div style={{height:20}} />
							</div>
						);
					}
					return null;
				})
			}

		</div>
	);

};

// Never re-render
export default TodoPage;