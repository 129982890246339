import React from "react";

import icon from '../../../assets/images/EventMissedPenalty.svg';

const MissedPenalty = (props) => {

	const data = props.data;
	const className = `event missed-penalty ${ props.isHomeTeam ? 'home-team' : 'away-team' }`;

	return (
		<div className={ className }>
			<div className="icon">
				<img src={ icon } alt="Missed penalty"/>
			</div>
			<div className="minute">{ data.minute }'</div>
			<div className="details">
				<div className="label">Missed penalty</div>
				<div className="player1">{ data.playerInvolved1 }</div>
				{(() => {
					if(data.playerInvolved2) {
						return (
							<div className="player2">({ data.playerInvolved2 })</div>
						);
					}
				})()}
			</div>
		</div>
	);
};

export default React.memo(MissedPenalty, (prevProps, nextProps) => {

	return prevProps.isHomeTeam === nextProps.isHomeTeam
		&& prevProps.data.minute === nextProps.data.minute
		&& prevProps.data.playerInvolved1 === nextProps.data.playerInvolved1
		&& prevProps.data.playerInvolved2 === nextProps.data.playerInvolved2;
});