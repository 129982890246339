import React from "react";
import { useParams } from "react-router-dom";

import '../../assets/scss/components/team/TeamPage.scss';

import { isHumanTeam } from '../../utils/helpers';

import TeamPageNPC from './TeamPageNPC';
import TeamPageHuman from './TeamPageHuman';

const TeamPage = () => {

	const { slug } = useParams();

	if(isHumanTeam(slug)) {
		return <TeamPageHuman
			slug={ slug }
		/>;
	}
	else {
		return <TeamPageNPC
			slug={ slug }
		/>;
	}
};

export default React.memo(TeamPage, () => {
	return true;
});