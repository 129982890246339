import React from "react";

const NoEvent = (props) => {

	const data = props.data;

	return (
		<div className="event no-event">
			<div className="info">No event available with type of <strong>{ data.type }</strong></div>
			<div className="icon" />
		</div>
	);
};

export default React.memo(NoEvent, () => {
	return true;
});