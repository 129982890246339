import React from 'react';

import '../../assets/scss/components/layout/Logout.scss';

import { signout } from '../../services/Firebase';

const Logout = () => {

	const handleLogout = (e) => {

		e.preventDefault();
		try {
			signout();
		} catch (error) {
			console.log('Logout.handleLogout()');
		}
	}

	return (
		<button type="button" className="logout-btn" onClick={ handleLogout }>
			<span>Logout</span>
		</button>
	)

};

export default Logout;