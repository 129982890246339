import React from 'react';

import refereeIcon from '../../assets/images/Referee.svg';
import trophyIcon from '../../assets/images/Trophy.svg';

import '../../assets/scss/components/match-centre/GameInfo.scss';
import IconAndValue from '../layout/IconAndValue';

import { translateCompetitionName } from '../../utils/helpers';

const GameInfo = (props) => {

	const { referee, weather, season, competition } = { ...props.data }

	return (
		<div className="game-info">

			<IconAndValue
				className="referee"
				label="Referee"
				icon={ refereeIcon }
				name={ referee }
			/>

			<IconAndValue
				className="competition"
				label={ `${translateCompetitionName(competition.name)}, Season ${ season }` }
				icon={ trophyIcon }
				name={ `Round ${ competition.round }` }
			/>

			<IconAndValue
				className="weather"
				label="Weather"
				icon={`/assets/weather/${weather}.svg`}
				name={ weather.toLowerCase().replace('_',' ') }
			/>

		</div>
	);

};
// Should component update
export default React.memo(GameInfo, (prevProps, nextProps) => {
	return prevProps.data.referee === nextProps.data.referee
		&& prevProps.data.weather === nextProps.data.weather
		&& prevProps.data.season === nextProps.data.season
		&& prevProps.data.competition.name === nextProps.data.competition.name
		&& prevProps.data.competition.round === nextProps.data.competition.round;
});