import React, { useEffect, useState } from 'react';
import { db } from '../../services/Firebase';
import { onValue, query, ref} from "firebase/database";

import '../../assets/scss/components/match-centre/TeamKit.scss';

import Kit from '../kits/Kit';

const TeamKit = (props) => {

	const { kitId, slug, callback } = {...props};
	const goalkeeper = ( props.goalkeeper ? props.goalkeeper : false );

	const [kit, setKit] = useState({});

	// Get Teams on "componentDidMount" and remove event listener on componentDidUnmount
	useEffect(() => {

		const dbRef = ref(db, `teams/${slug}/kits/${( kitId === '3rd' ? 'third' : kitId === '2nd' ? 'second' : 'first' )}`);
		const queryKit = query(dbRef);

		const unsubscribe = onValue(queryKit, (snapshot) => {

			const kit = {};

			snapshot.forEach((snap) => {
				kit[snap.key] = snap.val();
			});

			setKit(kit);

			if(callback) {
				callback(kit, goalkeeper);
			}
		});

		return () => {
			// A callback that can invoked to remove a listener.
			unsubscribe();
		}
	}, [slug, kitId, callback, goalkeeper]);

	const kitData = { ...kit };

	return (
	<div className="team-kit">
		<Kit
			data={kitData}
			goalkeeper={ goalkeeper }
		/>
	</div>);

};

// Should component update
export default React.memo(TeamKit, (prevProps, nextProps) => {
	return prevProps.kit === nextProps.kit;
});