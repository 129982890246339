import React from "react";

import icon from '../../../assets/images/EventWhistle.svg';

const Fulltime = (props) => {

	const score = props.score;

	return (
		<div className="event fulltime milestone">
			<div className="info">Full time<br />{ score.home } - { score.away }</div>
			<div className="icon">
				<img src={ icon } alt="PffT Pfft Pffffffffffft"/>
			</div>
		</div>
	);
};

// Should component update
export default React.memo(Fulltime, (prevProps, nextProps) => {
	return prevProps.score.home === nextProps.score.home && prevProps.score.away === nextProps.score.away;
});