export function getAvailableFormations() {
	return ['3-4-3','3-5-2','4-3-3','4-4-2','4-5-1','5-3-2','5-4-1'];
}

export function getPlayerPositionsInFormation(formation) {
	switch(formation) {
		case '3-4-3':
			return [
				getGoalkeeperPosition(),
				{ x:27, y:31.25 }, // Defender center
				{ x:73, y:31.25 }, // Defender center
				{ x:50, y:31.25 }, // Defender center
				{ x:38.75, y:50 }, // Midfield center
				{ x:61.5, y:50 }, // Midfield right
				{ x:16.25, y:50 }, // Midfield center
				{ x:83.5, y:50 }, // Midfield left
				{ x:27, y:68.25 }, // Forward center
				{ x:50, y:68.25 }, // Forward center
				{ x:73, y:68.25 }, // Forward center
			];

		case '3-5-2':
			return [
				getGoalkeeperPosition(),
				{ x:27, y:31.25 }, // Defender center
				{ x:73, y:31.25 }, // Defender center
				{ x:50, y:31.25 }, // Defender center
				{ x:28.5, y:50 }, // Midfield center
				{ x:71.5, y:50 }, // Midfield center
				{ x:11, y:53 }, // Midfield right
				{ x:50, y:50 }, // Midfield center
				{ x:38.75, y:68.25 }, // Forward center
				{ x:61.25, y:68.25 }, // Forward center
				{ x:89, y:53 }, // Midfield Left
			];

		case '4-3-3':
			return [
				getGoalkeeperPosition(),
				{ x:16.5, y:31.25 }, // Defender right
				{ x:83.5, y:31.25 }, // Defender left
				{ x:38.75, y:31.25 }, // Defender center
				{ x:61.25, y:31.25 }, // Defender center
				{ x:50, y:50 }, // Midfield center
				{ x:27, y:50 }, // Midfield center
				{ x:73, y:50 }, // Midfield center
				{ x:27, y:68.25 }, // Forward center
				{ x:50, y:68.25 }, // Forward center
				{ x:73, y:68.25 }, // Forward center
			];

		case '4-5-1':
			return [
				getGoalkeeperPosition(),
				{ x:16.5, y:31.25 }, // Defender right
				{ x:83.5, y:31.25 }, // Defender left
				{ x:38.75, y:31.25 }, // Defender center
				{ x:61.25, y:31.25 }, // Defender center
				{ x:28.5, y:50 }, // Midfield center
				{ x:11, y:53 }, // Midfield right
				{ x:71.5, y:50 }, // Midfield center
				{ x:50, y:50 }, // Midfield center
				{ x:50, y:68.25 }, // Forward center
				{ x:89, y:53 }, // Midfield Left
			];
		case '5-3-2':
			return [
				getGoalkeeperPosition(),
				{ x:11, y:34.25 }, // Defender right
				{ x:89, y:34.25 }, // Defender Left
				{ x:28.5, y:31.25 }, // Defender center
				{ x:71.5, y:31.25 }, // Defender center
				{ x:50, y:31.25 }, // Defender center
				{ x:27, y:50 }, // Midfield center
				{ x:50, y:50 }, // Midfield center
				{ x:38.75, y:68.25 }, // Forward center
				{ x:61.25, y:68.25 }, // Forward center
				{ x:73, y:50 }, // Midfield center
			];
		case '5-4-1':
			return [
				getGoalkeeperPosition(),
				{ x:11, y:34.25 }, // Defender right
				{ x:89, y:34.25 }, // Defender Left
				{ x:28.5, y:31.25 }, // Defender center
				{ x:71.5, y:31.25 }, // Defender center
				{ x:50, y:31.25 }, // Defender center
				{ x:16.5, y:50 }, // Midfield right
				{ x:38.75, y:50 }, // Midfield center
				{ x:61.25, y:50 }, // Midfield center
				{ x:50, y:68.25 }, // Forward center
				{ x:83.5, y:50 }, // Midfield left
			];

		case '4-4-2':
		default:
			return [
				getGoalkeeperPosition(),
				{ x:16.5, y:31.25 }, // Defender right
				{ x:83.5, y:31.25 }, // Defender left
				{ x:38.75, y:31.25 }, // Defender center
				{ x:61.25, y:31.25 }, // Defender center
				{ x:38.75, y:50 }, // Midfield center
				{ x:16.5, y:50 }, // Midfield right
				{ x:61.25, y:50 }, // Midfield center
				{ x:38.75, y:68.25 }, // Forward center
				{ x:61.25, y:68.25 }, // Forward center
				{ x:83.5, y:50 }, // Midfield left
			];
	}
}

export function getGoalkeeperPosition() {
	return { x: 50, y: 13 };
}