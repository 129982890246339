import React, { Suspense } from 'react';

import '../../assets/scss/components/kits/Kit.scss';
import Kit0 from './Kit0';

const Kit = (props) => {

	const goalkeeper = props.goalkeeper || false;
	const data = props.data;

	const shirtColor1 = data ? data.shirt_1 : null;
	const shirtColor2 = data ? data.shirt_2 : null;
	const shirtColor3 = data ? data.shirt_3 : null;
	const shortsColor1 = data ? data.shorts_1 : null;
	const shortsColor2 = data ? data.shorts_2 : null;
	const goalkeeper_1 = data ? data.goalkeeper_1 : null;

	let design = data ? parseInt(data.design) : 0;
	if(design < 0 || design > 16 || isNaN(design)) {
		design = 0;
	}

	if(goalkeeper) {
		design = 1;
	}

	const className = `kit kit-${design}`;
	const KitComponent = React.lazy(() => import('./Kit' + design));

	return (
		<div className={ className }>
			<Suspense fallback={<Kit0 />}>
				<KitComponent
					shirtColor1={ goalkeeper ? goalkeeper_1 : shirtColor1 }
					shirtColor2={ goalkeeper ? goalkeeper_1 : shirtColor2 }
					shirtColor3={ goalkeeper ? goalkeeper_1 : shirtColor3 }
					shortsColor1={ goalkeeper ? goalkeeper_1 : shortsColor1 }
					shortsColor2={ goalkeeper ? goalkeeper_1 : shortsColor2 }
				/>
				{(() => {
					if(design === 0) {
						return (
							<div className="missing">Mangler design</div>
						);
					}
				})()}
			</Suspense>
		</div>
	);
};

// Never re-render
export default React.memo(Kit, (prevProps, nextProps) => {

	if(prevProps.data && nextProps.data) {
		return prevProps.data.design === nextProps.data.design
			&& prevProps.data.shirt_1 === nextProps.data.shirt_1
			&& prevProps.data.shirt_2 === nextProps.data.shirt_2
			&& prevProps.data.shirt_3 === nextProps.data.shirt_3
			&& prevProps.data.shorts_1 === nextProps.data.shorts_1
			&& prevProps.data.shorts_2 === nextProps.data.shorts_2
			&& prevProps.data.goalkeeper_1 === nextProps.data.goalkeeper_1;
	}
	return false;
});