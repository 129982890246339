import React, { useEffect, useRef, useState } from "react";

import { NavLink } from "react-router-dom";
import { auth } from '../../services/Firebase';

import '../../assets/scss/components/layout/Header.scss';
import TeamLogoLogin from '../../assets/images/TeamLogoLogin.svg';

import { getTeamFromUser } from '../../utils/helpers';
import { useScrollPosition } from '../hooks/useScrollPosition';

import MenuPrimary from './MenuPrimary';
import MenuSecondary from './MenuSecondary';
import Logo from './Logo';
import PrimaryMenuToggler from './PrimaryMenuToggler';

const Header = () => {

	const [sticky, setSticky] = useState(true);
	const [showSecondaryMenu, setShowSecondaryMenu] = useState(false);

	const refActionToggleSecondaryMenu = useRef();

	const toggleSecondaryMenu = (e) => {
		e.preventDefault();
		setShowSecondaryMenu( !showSecondaryMenu );
	};

	useScrollPosition(
		({ prevPos, currPos }) => {

			const isShow = currPos.y > -40 || currPos.y > prevPos.y
			if (isShow !== sticky) {
				setSticky(isShow)
			}
		},
		sticky
	);

	useEffect(() => {

		const handleClickOutside = (e) => {

			if(!auth.currentUser) {
				return ;
			}

			if(refActionToggleSecondaryMenu.current.contains(e.target)) {
				// inside click, prevent closing and opening it again
				return;
			}

			if(showSecondaryMenu) {
				setShowSecondaryMenu(false);
			}
		};

		// add when mounted
		document.addEventListener("click", handleClickOutside);
		// return function to be called when unmounted
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [showSecondaryMenu]);

	const team = getTeamFromUser(auth.currentUser);
	const [ teamID, teamName, teamLogo ] = [ team.id, team.name, team.logo ];

	//console.log('Header.render()');

	return (
		<header id="the-header" className={sticky ? 'js-sticky' : 'js-unsticky'}>
			<div className="header-inner">

				<Logo />

				<MenuPrimary />

				<div className="actions">

					{(() => {

						if(teamID && teamName && teamLogo) {
							return (
								<button type="button" className="action team-logo" onClick={toggleSecondaryMenu} ref={ refActionToggleSecondaryMenu }>
									<img src={ teamLogo } alt={ teamName } />
									<span>{ teamName }</span>
								</button>
							);
						}
						else {
							return (
								<NavLink to="/login" className="action team-login">
									<img src={ TeamLogoLogin } alt="Login" />
									<span>Login</span>
								</NavLink>
							);
						}

					})()}

				</div>

				<PrimaryMenuToggler />

				{
					showSecondaryMenu && sticky ? <MenuSecondary /> : null
				}

			</div>
		</header>
	);
};

export default Header;