import React from 'react';

import '../../assets/scss/components/team/Fixture.scss';

const Fixture = (props) => {

	const { home, opponent, result, round } = { ...props.data }
	const teamName = !opponent ? null : props.teamName;

	const className = `fixture fixture-${ home ? 'home' : 'away' }`

	return (
		<div className={className}>
			<div className="round">{ round }</div>
			{(() => {
				// If the team is at home
				if(home) {
					return (
						<div className="teams">
							<div className="team team-1">{ teamName }</div>
							<div className="score"><span>{ result.for }</span><span className="seperator" /><span>{ result.against }</span></div>
							<div className="team team-opponent">{ opponent }</div>
						</div>
					);
				}

				return (
					<div className="teams">
						<div className="team team-opponent">{ opponent }</div>
						<div className="score"><span>{ result.against }</span><span className="seperator" /><span>{ result.for }</span></div>
						<div className="team team-1">{ teamName }</div>
					</div>
				);

			})()}
		</div>
	);
};

// Never re-render
export default React.memo(Fixture, (prevProps, nextProps) => {

	return prevProps.data.home === nextProps.data.home
		&& prevProps.data.opponent === nextProps.data.opponent
		&& prevProps.data.round === nextProps.data.round
		&& prevProps.data.result.for === nextProps.data.result.for
		&& prevProps.data.result.against === nextProps.data.result.against;
});