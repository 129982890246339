import React from "react";
import Form from '../team/Form';
import { NavLink } from 'react-router-dom';
import { isHumanTeam } from '../../utils/helpers';

const TableTeam = React.memo((props) => {

	const { highlight, data, limit } = { ...props };
	const { position, team, played, won, drawn, lost, gf, ga, gd, points, form } = { ...data };

	const formResults = form ? form.split(',').slice(0, limit || 5) : null;
	const teamName = team ? team.name || null : null;
	const teamSlug = team ? team.slug || null : null;

	return (
		<div
			className="table-team"
			data-human={ isHumanTeam(teamSlug) ? 'true' : 'false' }
			data-highlight={ highlight ? 'true' : 'false' }
		>
			<div className="position">{ position }</div>
			<div className="team">{ teamName }</div>
			<div className="played">{ played }</div>
			<div className="won">{ won }</div>
			<div className="drawn">{ drawn }</div>
			<div className="lost">{ lost }</div>
			<div className="for">{ gf }</div>
			<div className="against">{ ga }</div>
			<div className="gd">{ gd }</div>
			<div className="points">{ points }</div>
			<div className="form-guide">
				{
					formResults ? <Form results={ formResults } />: null
				}
			</div>
			{
				teamSlug ? <NavLink to={ `/teams/${teamSlug}/` } className="slug" /> : null
			}
		</div>
	);

});

export default TableTeam;