import React, { useEffect, useState } from 'react';

import '../../assets/scss/components/dice/Log.scss';

import LogItem from './LogItem';
import { isGameMaster } from '../../utils/helpers';

const Log = (props) => {

	const {rolls, loading, name} = {...props};
	const [delayDisplayingTheRoll, setDelayDisplayingTheRoll] = useState(false);
	const isUserTheGameMaster = isGameMaster();

	useEffect(() => {

		if(!loading) {
			setDelayDisplayingTheRoll(true);
		}

	}, [loading]);

	return (
		<ul className="log">
			{
				Object.keys(rolls).map((index) => {
					return <LogItem
						key={ rolls[index].roll_id }
						data={ rolls[index] }
						name={ name }
						awaiting={ delayDisplayingTheRoll }
						isGameMaster={ isUserTheGameMaster }
					/>
				})
			}
		</ul>
	)
};

export default Log;