import React, { useEffect, useState } from 'react';

import '../../assets/scss/components/tactics/TacticBoard.scss';

import { auth, db } from '../../services/Firebase';

import Pitch from './Pitch';
import TeamFormations from './TeamFormations';
import PlayerPositions from './PlayerPositions';
import { getUserIdFromTeamSlug, isGameMaster } from '../../utils/helpers';
import HiddenPlayerPositions from './HiddenPlayerPositions';
import {onValue, query, ref, update} from "firebase/database";

const TacticBoard = (props) => {

	const { slug, players, kitId, isHomeTeam } = { ...props }

	// Remove all empty values from players array (@Todo: Why are there empty values in this array?)
	const fieldedPlayers = players.filter(item => item).slice(0,11);

	// User + Team Management
	const user = auth.currentUser;
	const userIdTeam = getUserIdFromTeamSlug(slug);

	const [data, setData] = useState({
		loading: true,
		formation: {
			team: '4-4-2', // default formation
			players: null, // default is no custom player positions
		},
		hidden: false, // default value
	})

	// Get Players positions and Team formation on "componentDidMount" and remove event listener on componentDidUnmount
	useEffect(() => {

		const dbRef = ref(db, `teams/${slug}/match-centre/tactic-board/`);
		const queryTacticBoard = query(dbRef);

		const unsubscribe = onValue(queryTacticBoard, (snapshot) => {

			const newData = {
				formation: {
					team: '4-4-2', // default formation
					players: null, // default is no custom player positions
				},
				hidden: false,
			};
			snapshot.forEach((snap) => {
				newData[snap.key] = snap.val();
			});

			setData({
				loading: false,
				...newData,
			});
		});

		return () => {
			// A callback that can invoked to remove a listener.
			unsubscribe();
		}
	}, [slug]);

	// Formations
	const saveTeamFormation = (formation) => {

		if(window.confirm(`This will reset the formation back to a default ${formation}...?!`)) {

			update(ref(db, `teams/${slug}/match-centre/tactic-board/formation/`), {
				team: formation,
				players: null,
			}).then(_ => {
				//console.log('saveTeamFormation SUCCESS', formation);
			}).catch((error) => {
				// The write failed...
				//console.log('saveTeamFormation ERROR', error);
			});
		}
	};

	// Save hidden state in Firebase
	const saveHiddenState = (hidden) => {

		// Get the User logged in
		const user = auth.currentUser;

		// If no user is logged in then we can't continue
		if(!user) {
			alert('You need to log in...');
			return false;
		}

		// If the User and team's User doesn't match, then we can't continue
		const userIdTeam = getUserIdFromTeamSlug(slug);
		if(userIdTeam !== user.uid) {
			alert('You are not the owner of ' + slug);
			return false;
		}

		update(ref(db, `teams/${slug}/match-centre/tactic-board/`), {
			hidden,
		}).then(_ => {
			//console.log('saveHiddenState SUCCESS', hidden);
		}).catch((error) => {
			// The write failed...
			//console.log('saveHiddenState ERROR', error);
		});
	}

	if(data.loading) {
		return (
			<div className="tactic-board">
				<Pitch />
			</div>
		);
	}

	const cbId = `tactic-board-hidden-${slug}`;
	const cbDisabled = !user || userIdTeam !== user.uid;

	// GameMaster can always see the formation, as can the owner of the team
	const hidden = ( data.hidden ? !isGameMaster() && userIdTeam !== user.uid : data.hidden );

	return (
		<div className="tactic-board">

			<Pitch />

			{(() => {

				if(!hidden) {
					return (
						<PlayerPositions
							players={fieldedPlayers}
							slug={slug}
							kitId={kitId}
							formation={data.formation.team}
							playerPositions={data.formation.players ? data.formation.players : {}}
							reverse={ isHomeTeam }
						/>
					);
				}
				else {
					return <HiddenPlayerPositions />;
				}

			})()}

			<TeamFormations
				slug={ slug }
				formation={ data.formation.team }
				saveTeamFormation={ saveTeamFormation }
				hidden={ hidden }
			/>

			<div className="checkbox">
				<input
					id={ cbId }
					type="checkbox"
					checked={ data.hidden }
					disabled={ cbDisabled }
					onChange={ (e) => {
						if( !user || userIdTeam !== user.uid ) { // If not your team => always hide
							setData({
								...data,
								hidden: true,
							});
						}
						else {
							saveHiddenState( e.target.checked );
						}
					}}
				/>
				<label
					htmlFor={ cbId }
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 847 847"
						fillRule="evenodd"
						clipRule="evenodd"
						shapeRendering="geometricPrecision"
						textRendering="geometricPrecision"
						imageRendering="optimizeQuality"
						className="checkmark"
					>
						<path d="M299 522l396-396c18-18 47-18 65 0l51 51c18 18 18 47 0 64L332 721c-18 18-47 18-65 0L35 489c-17-17-17-46 0-64l52-51c17-18 46-18 64 0l148 148z" />
					</svg>
					<span className="copy">{ userIdTeam === user.uid ? 'Formation is secret to other human players' : 'Formation is secret to other human players' }</span>
				</label>
			</div>

		</div>
	);

};

export default TacticBoard;