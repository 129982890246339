import React from 'react';

const PrimaryMenuToggler = () => {

	return (
		<button
			type="button"
			id="primary-menu-toggler"
			onClick={ (e) => {
				e.preventDefault();
				document.body.classList.toggle('js-primary-menu-revealed');
			}}
		/>
	);
}

// Never re-render
export default React.memo(PrimaryMenuToggler, (prevProps, nextProps) => {
	return true;
});