import React, {useEffect,useState} from "react";

import {leadingZero, wait, SIMULATED_WAIT_TIME } from '../../utils/helpers';
import useIsMounted from '../../hooks/useIsMounted';

const LogItem = (props) => {

	const {isGameMaster, data, name} = {...props};
	
	const [awaiting, setAwaiting] = useState( props.awaiting );
	const isMounted = useIsMounted();

	const date = new Date(data.now);
	const dateString = `${leadingZero(date.getDate())}.${leadingZero(date.getMonth()+1)} ${leadingZero(date.getHours())}:${leadingZero(date.getMinutes())}:${leadingZero(date.getSeconds())}`;

	let className = `log-item`;

	useEffect(() => {

		async function waitForIt() {

			await wait(SIMULATED_WAIT_TIME);

			// The item may not be mounted anymore
			if(isMounted.current) {
				setAwaiting(false);
			}
		}

		if(isMounted.current && awaiting) {
			waitForIt();
		}

	}, [awaiting, isMounted]);

	// Output the suspense ...
	if(awaiting) {
		return (
			<li className={className}>
				<div className="datetime">{ dateString }</div>
				<div className="throw"><span className="team-name">{ name }</span> rolls the dice...</div>
			</li>
		);
	}

	// Or if it's hidden ...
	if(data.hidden) {
		if(!isGameMaster) {
			className = `${className} hidden`;

			return (
				<li className={className}>
					<div className="datetime">{ dateString }</div>
					<div className="throw"><span className="team-name">{ name }</span> rolled <span className="value">??</span></div>
				</li>
			);
		}
		else {
			return (
				<li className={className}>
					<div className="datetime">{ dateString }</div>
					<div className="throw"><span className="team-name">{ name }</span> rolled <span className="value">{ leadingZero(data.roll) }</span>&nbsp;&nbsp;<span>(Secret)</span></div>
				</li>
			);
		}
	}

	// Output the result
	return (
		<li className={className}>
			<div className="datetime">{ dateString }</div>
			<div className="throw"><span className="team-name">{ name }</span> rolled <span className="value">{ leadingZero(data.roll) }</span></div>
		</li>
	);

}

// Should component update
export default React.memo(LogItem, (prevProps, nextProps) => {
	return prevProps.awaiting === nextProps.awaiting;
});