import React, { useEffect, useState } from 'react';

import { db } from '../../services/Firebase';
import {onValue, query, ref} from "firebase/database";
import { NavLink } from 'react-router-dom';

import Kit from '../kits/Kit';

const Team = (props) => {

	const { name, slug } = { ...props.data };
	const link = `/teams/${slug}/`;

	const [kit, setKit] = useState({});

	// Get Teams on "componentDidMount" and remove event listener on componentDidUnmount
	useEffect(() => {

		const dbRef = ref(db, `teams/${slug}/kits/first`);
		const queryKit = query(dbRef);

		const unsubscribe = onValue(queryKit, (snapshot) => {

			const kit = {};

			snapshot.forEach((snap) => {
				kit[snap.key] = snap.val();
			});
			setKit(kit);
		});

		return () => {
			// A callback that can invoked to remove a listener.
			unsubscribe();
		}
	}, [slug]);

	const kitData = { ...kit };

	return (
		<NavLink
			to={link}
			className="team"
		>
			<Kit data={kitData}	/>
			<span className="name">{ name }</span>
		</NavLink>
	);
};


// Never re-render
export default React.memo(Team, (prevProps, nextProps) => {
	return true;
});