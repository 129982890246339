import React, { useState } from 'react';

import '../../../assets/scss/components/dev/KitDesigner.scss';

import { ChromePicker } from 'react-color';

import DevKit from './DevKit';

const KitDesigner = () => {

	const [shirtColor1, setShirtColor1] = useState('#FF0000');
	const [shirtColor2, setShirtColor2] = useState('#990000');
	const [shirtColor3, setShirtColor3] = useState('#FFFF00');
	const [shortsColor1, setShortsColor1] = useState('#FFFFFF');
	const [shortsColor2, setShortsColor2] = useState('#FFFF00');

	const numKits = 16;

	return (
		<div className="page kit-designer-page">
			<h1>Kit Designer</h1>
			<p>Just copy/paste each #hex color into the Spreadsheet...</p>

			<div className="color-pickers">

				<div className="labels">
					<h6 className="label">Shirt 1</h6>
					<h6 className="label">Shirt 2</h6>
					<h6 className="label">Shirt 3</h6>
					<h6 className="label">Shorts 1</h6>
					<h6 className="label">Shorts 3</h6>
				</div>
				<div className="pickers">
					<ChromePicker
						color={shirtColor1}
						onChange={e => setShirtColor1(e.hex)}
					/>
					<ChromePicker
						color={shirtColor2}
						onChange={e => setShirtColor2(e.hex)}
					/>
					<ChromePicker
						color={shirtColor3}
						onChange={e => setShirtColor3(e.hex)}
					/>
					<ChromePicker
						color={shortsColor1}
						onChange={e => setShortsColor1(e.hex)}
					/>
					<ChromePicker
						color={shortsColor2}
						onChange={e => setShortsColor2(e.hex)}
					/>
				</div>

			</div>

			<div className="dev-kits">
				{
					Object.keys(Array.from(Array(numKits).keys())).map((key, i) => {

						key = `devkit${key}`;

						return <DevKit
							key={ key }
							design={ (i + 1) }
							shirtColor1={shirtColor1}
							shirtColor2={shirtColor2}
							shirtColor3={shirtColor3}
							shortsColor1={shortsColor1}
							shortsColor2={shortsColor2}
						/>

					})
				}
			</div>

		</div>
	);
}

export default KitDesigner;