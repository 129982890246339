import Kit from '../kits/Kit';

import '../../assets/scss/components/team/Kits.scss';

const Kits = (props) => {

	const { kits } = { ...props };

	return (
		<div className="kits">
			<div className="kit-wrapper">
				<Kit
					data={kits.first}
				/>
				<div className="what">Home kit</div>
			</div>
			<div className="kit-wrapper">
				<Kit
					data={kits.second}
				/>
				<div className="what">Away kit</div>
			</div>
			<div className="kit-wrapper">
				<Kit
					data={kits.third}
				/>
				<div className="what">Third kit</div>
			</div>
		</div>
	);

};

export default Kits;