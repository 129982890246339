import React, { useEffect, useState } from "react";

import { db } from '../../services/Firebase';

import '../../assets/scss/components/match-centre/MatchCentrePage.scss';

import Card from '../layout/Card';
import Columns from '../layout/Columns';
import Column from '../layout/Column';

import Score from './Score';
import GameInfo from './GameInfo';
import Events from './Events';
import Team from './Team';
import MatchReportSaver from './MatchReportSaver';
import { isHumanTeam } from '../../utils/helpers';
import TeamDice from '../dice/TeamDice';
import {onValue, query, ref} from "firebase/database";

const MatchCentrePage2 = () => {

	const [data, setData] = useState({
		loading: true,
		saving: false,
		matchCentre: {},
	});

	// Get Teams on "componentDidMount" and remove event listener on componentDidUnmount
	useEffect(() => {

		const dbRef = ref(db, "match-centre-2/");
		const queryMatchCentre = query(dbRef);

		const unsubscribe = onValue(queryMatchCentre, (snapshot) => {

			const matchCentre = {}

			snapshot.forEach((snap) => {
				matchCentre[snap.key] = snap.val();
			});

			setData({
				loading: false,
				saving: false,
				matchCentre,
			});
		});

		return () => {
			// A callback that can invoked to remove a listener.
			unsubscribe();
		}
	}, []);

	if(data.loading) {
		return (
			<div className="page match-centre-page">
				<h1 className="page-title">Match Centre 2</h1>
				<div className="loading">LOADING</div>
			</div>
		);
	}

	const matchcentre = data.matchCentre;
	const homeTeam = matchcentre.teams.home;
	const awayTeam = matchcentre.teams.away;

	const allHumanTeams = ( isHumanTeam(homeTeam.slug) && isHumanTeam(awayTeam.slug) );

	return (
		<div className="page match-centre-page">

			<h1 className="page-title">Match Centre 2</h1>

			<Columns>
				<Column>
					<Card
						title={ homeTeam.name }
					>
						<Team
							teamData={ homeTeam }
							players={ matchcentre.players__home }
							isHomeTeam={ true }
						/>
					</Card>
				</Column>
				<Column>
					<Card
						title={ allHumanTeams ? 'Game Master' : null }
					>
						{(() => {
							// Game Master needs a pair of dice if both teams are human
							if(allHumanTeams) {
								return (
									<TeamDice
										slug="gamemaster"
										name="Game Master"
										limit={ 5 }
									/>
								);
							}
						})()}
						<Score
							score={ matchcentre.score.data }
						/>
						<GameInfo
							data={ matchcentre.gameinfo.data }
						/>
						<Events
							events={ matchcentre.events }
							nameHomeTeam={ homeTeam.name }
						/>
						<MatchReportSaver
							id={ matchcentre.gameinfo.data.match_id }
							report={ matchcentre }
						/>
					</Card>

				</Column>
				<Column>
					<Card
						title={ awayTeam.name }
					>
						<Team
							teamData={ awayTeam }
							players={ matchcentre.players__away }
							isHomeTeam={ false }
						/>
					</Card>
				</Column>

			</Columns>

		</div>
	);
};

export default MatchCentrePage2;