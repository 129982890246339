import React from 'react';

import '../../assets/scss/components/match-centre/TeamInfo.scss';

import Manager from '../team/Manager';
import Tactic from './Tactic';

const TeamInfo = (props) => {

	const { manager, tactics } = {...props.teamInfo};

	return (
		<div className="team-info">
			<div className="icon-and-values">
				<Manager
					name={ manager }
				/>
				<Tactic
					data={ tactics }
				/>
			</div>
		</div>
	);

};

// Never re-render
export default React.memo(TeamInfo, (prevProps, nextProps) => {
	return prevProps.teamInfo.tactics.counter === nextProps.teamInfo.tactics.counter && prevProps.teamInfo.manager === nextProps.teamInfo.manager;
});