import React from "react";

const TableHeader = (props) => {

	const { limit } = { ...props };

	return (
		<div className="table-header">
			<div className="position">#</div>
			<div className="team">Team</div>
			<div className="played">Pl</div>
			<div className="won">W</div>
			<div className="drawn">D</div>
			<div className="lost">L</div>
			<div className="for">F</div>
			<div className="against">A</div>
			<div className="gd">+/-</div>
			<div className="points">Pts</div>
			<div className="form-guide">
				<div className="form">Last { limit || 5 }</div>
			</div>
		</div>
	);

};

// Never re-render
export default React.memo(TableHeader, (prevProps, nextProps) => {
	return true;
});