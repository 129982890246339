import React, { useEffect, useState } from 'react';
import { auth, db } from '../../services/Firebase';
import {onValue, query, ref, set} from "firebase/database";

import '../../assets/scss/components/match-centre/MatchReportSaver.scss';

import { getTeamFromUser, leadingZero } from '../../utils/helpers';

const MatchReportSaver = (props) => {

	const { id, report }  = { ...props };

	const [data, setData] = useState({
		saving: false,
		context: {}, // describes who and when the last game data was saved
	});

	// Get Teams on "componentDidMount" and remove eventlistener on componentDidUnmount
	useEffect(() => {

		const dbRef = ref(db, `archive/fixtures/season${report.gameinfo.data.season}/${id}/context/`);
		const queryContext = query(dbRef);

		const unsubscribe = onValue(queryContext, (snapshot) => {

			const { uid, timestamp } = { ...snapshot.val() }

			setData({
				saving: false,
				context: {
					uid,
					timestamp,
				},
			})
		});

		return () => {
			// A callback that can invoked to remove a listener.
			unsubscribe();
		}
	}, [id, report.gameinfo.data.season]);

	// Save Game
	const saveGame = () => {

		// Get the User logged in
		const user = auth.currentUser;

		// If no user is logged in then we can't continue
		if(!user) {
			alert('You need to log in...');
			return false;
		}

		if(report.events.length <= 1) { // KICKOFF is the 1st event
			alert("Nothing's happened yet...");
			return false;
		}

		if(window.confirm("Are you sure? This will override any previous save...")) {

			// We are saving...
			const newData = { ...data };
			newData.saving = true;
			setData(newData);

			set(ref(db, `archive/fixtures/season${report.gameinfo.data.season}/${id}/`), {
				context: {
					timestamp: Date.now(),
					uid: user.uid,
					id,
				},
				report,
			}).then(_ => {
				//console.log('MatchReportSaver SUCCESS', newData);
			}).catch((error) => {
				// The write failed...
				//console.log('MatchReportSaver ERROR', error);
			});
		}
	}

	const context = data.context;

	if(report.events.length <= 1) {
		return (
			<div className="match-report-saver">
				<div>MatchId: <strong>{ id }</strong></div>
			</div>
		);
	}

	return (
		<div className="match-report-saver">
			<button
				type="button"
				className="save-match-report"
				disabled={ data.saving }
				onClick={(e) => {
					e.preventDefault();
					saveGame();
				}}
			>
				<span>{ data.saving ? 'Saving...' : 'Save match report' }</span>
			</button>
			<div>MatchId: <strong>{ id }</strong></div>

			{(() => {
				if(context.uid && context.timestamp) {
					const {uid, timestamp} = { ...context };
					const date = new Date(timestamp);
					const dateString = `${leadingZero(date.getDate())}.${leadingZero(date.getMonth()+1)} ${leadingZero(date.getHours())}:${leadingZero(date.getMinutes())}:${leadingZero(date.getSeconds())}`;

					return (
						<div className="last-saved">Last saved: { dateString } by <strong>{ getTeamFromUser(uid).name }</strong></div>
					);
				}
				return (<div className="last-saved">Game hasn't been saved yet...</div>);

			})()}

		</div>
	);

}

// Should component update
export default React.memo(MatchReportSaver, (prevProps, nextProps) => {
	// The component will automatically update every time we save to Firebase,
	// but we don't need it to re-render every time there's a change to some other component...
	return prevProps.id === nextProps.id;
});