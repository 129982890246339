import React, { Suspense } from 'react';

const DevKit = React.memo((props) => {

	const { shirtColor1, shirtColor2, shirtColor3, shortsColor1, shortsColor2 } = { ...props };

	let design = parseInt(props.design);
	if(props.design < 1 || props.design > 16) {
		design = 1;
	}

	const KitComponent = React.lazy(() => import('../../kits/Kit' + design));

	return (
		<div className="dev-kit">
			<div className="dev-kit-inner">

				<h6 className="design">Design: { design }</h6>

				<Suspense fallback={<div className="getting-dressed"><div className="label">Getting dressed...</div></div>}>
					<section>
						<KitComponent
							shirtColor1={ shirtColor1 }
							shirtColor2={ shirtColor2 }
							shirtColor3={ shirtColor3 }
							shortsColor1={ shortsColor1 }
							shortsColor2={ shortsColor2 }
						/>
					</section>
				</Suspense>
			</div>
		</div>
	);
});

export default DevKit;