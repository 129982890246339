import React, { useState, useEffect } from "react";

import { db } from '../../services/Firebase';

import Columns from '../layout/Columns';
import Column from '../layout/Column';
import Card from '../layout/Card';

import TeamSummary from './TeamSummary';
import Kits from './Kits';
import Fixtures from './Fixtures';
import Squad from './Squad';
import Manager from './Manager';
import Stadium from './Stadium';
import TacticPreferred from './TacticPreferred';

import { getFormFromFixtures, sortFixturesBy } from '../../utils/helpers';

import iconRotatePhone from '../../assets/images/RotatePhone.svg';
import LeaguePosition from './LeaguePosition';
import {onValue, query, ref} from "firebase/database";

// The HUMAN teams are equal to the NPC teams (as of now: january 2021)
// But hopefully that will change soon...
const TeamPageHuman = (props) => {

	const { slug } = { ...props };
	const [data, setData] = useState({
		loading: true,
		teamData: {},
	});

	// Get Teams on "componentDidMount" and remove eventlistener on componentDidUnmount
	useEffect(() => {

		const dbRef = ref(db, `teams/${slug}`);
		const queryTeam = query(dbRef);

		const unsubscribe = onValue(queryTeam, (snapshot) => {

			const teamData = {}

			snapshot.forEach((snap) => {
				teamData[snap.key] = snap.val();
			});

			setData({
				loading: false,
				teamData,
			});
		});

		return () => {
			// A callback that can invoked to remove a listener.
			unsubscribe();
		}

	}, [slug]);

	if(data.loading) {
		return (
			<section className="page team-page team-page-human">
				<div className="loading">Stepping into the office...</div>
			</section>
		);
	}

	// Data managing
	const teamData = data.teamData;

	if(Object.keys(data.teamData).length <= 0 || !teamData.team) {
		return (
			<section className="page team-page team-page-npc">
				<TeamSummary
					name={ `Team slug: ${ slug }` }
				/>
				<div>No data available...</div>
			</section>
		);
	}

	// Team info
	const teamInfo = teamData.team.data;
	const { name, league, manager, stadium, tactic } = { ...teamInfo };

	// Fixtures
	const fixturesRegularSeason = sortFixturesBy(teamData.fixtures__regular_season);
	const fixturesPlayoff = sortFixturesBy(teamData.fixtures__playoff);
	const fixturesUsCup = sortFixturesBy(teamData.fixtures__us_cup);

	// Squad
	const squad = teamData.squad;

	// Kits
	const kits = teamData.kits;

	return (
		<section className="page team-page team-page-human">

			<TeamSummary
				name={ name }
				league={ league }
				results={ getFormFromFixtures(fixturesRegularSeason, 5) }
			/>

			<Columns>
				<Column>

					<Card
						title="Team info"
						className="card-team-info"
					>
						<Kits
							kits={ kits }
						/>
						<div className="icon-and-values">
							<Manager
								name={ manager }
							/>
							<Stadium
								data={ stadium }
							/>
							<TacticPreferred
								data={ tactic }
							/>
						</div>
					</Card>

					<Card
						title={`Fixtures ${ league.division }`}
					>
						<Fixtures
							fixtures={ fixturesRegularSeason }
							teamName={ name }
						/>
						<Fixtures
							fixtures={ fixturesPlayoff }
							teamName={ name }
						/>
					</Card>

				</Column>
				<Column>

					<Card
						title={ `League position ${ league.division }` }
						className="card-league-position"
						icon={ iconRotatePhone }
					>
						<LeaguePosition
							slug={ slug }
							league={ league.division.toLowerCase() }
						/>
					</Card>

					<Card
						title="Squad"
						className="card-squad"
						icon={ iconRotatePhone }
					>
						<Squad
							players={ squad }
						/>
					</Card>

					<Card
						title="Fixtures US Cup"
					>
						<Fixtures
							fixtures={ fixturesUsCup }
							teamName={ name }
						/>
					</Card>

				</Column>

			</Columns>

		</section>
	);
};

export default TeamPageHuman;