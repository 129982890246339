import React from 'react';

import { numberWithOrdinal } from '../../utils/helpers';

import '../../assets/scss/components/team/TeamSummary.scss';
import Form from './Form';

const TeamSummary = (props) => {

	const { name, league, results } = { ...props };

	return (
		<div className="team-summary">
			<h1 className="team-name">{ name }</h1>
			{(() => {
				if(league) {
					return (
						<div className="current-position">
							{ league.position
								? `${numberWithOrdinal( league.position )} place in ${league.division}.`
								: `${league.division}.`
							}
							&nbsp;
						</div>
					);
				}
			})()}
			<Form
				label="Current form"
				results={ results }
			/>
		</div>
	);

};

// Never re-render
export default React.memo(TeamSummary, (prevProps, nextProps) => {
	if(prevProps.league && nextProps.league) {
		return prevProps.name === nextProps.name && prevProps.league.position === nextProps.league.position && prevProps.league.division === nextProps.league.division;
	}
	return prevProps.name === nextProps.name;
});