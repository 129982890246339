import { initializeApp } from "firebase/app"
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseApp = initializeApp({
	projectId: "smrpg-staging",
	storageBucket: "smrpg-staging.appspot.com",
	messagingSenderId: "915526897441",
	appId: "1:915526897441:web:837044a330494559d0b860",
	apiKey: "AIzaSyAhYWv-sXiFL1mu-Zu0ocF-NH-LkMz4thc",
	authDomain: "smrpg-staging.firebaseapp.com",
	databaseURL: "https://smrpg-staging.firebaseio.com",
});

export function onAuthStateChange(callback) {
	return auth.onAuthStateChanged(user => {
		if(user) {
			callback(user);
		}
		else {
			callback(user);
		}
	});
}

export function signin(email, password) {
	return signInWithEmailAndPassword(auth, email, password);
}

export function signout() {
	signOut(auth).then(() => {
		// Sign-out successful.
	}).catch((error) => {
		// An error happened.
	});
}

export const auth = getAuth(firebaseApp);
export const db = getDatabase();