import React, { useEffect, useState } from "react";
import { db } from '../../services/Firebase';
import {onValue, orderByChild, query, ref} from "firebase/database";

import '../../assets/scss/components/teams/TeamsPage.scss';

import TeamsList from './TeamsList';
import Columns from '../layout/Columns';
import Column from '../layout/Column';
import Card from '../layout/Card';

const TeamsPage = () => {

	const [data, setData] = useState({
		loading: true,
		teams: {},
	});

	// Get Teams on "componentDidMount" and remove eventlistener on componentDidUnmount
	useEffect(() => {

		const dbRef = ref(db, "global-data/teams/");
		const queryConstraints = [orderByChild('name')];
		const queryTeams = query(dbRef, ...queryConstraints);

		const unsubscribe = onValue(queryTeams, (snapshot) => {

			const teams = {};

			snapshot.forEach((snap) => {

				const val = snap.val();

				if(!teams[val.league]) {
					teams[val.league] = {};
				}
				teams[val.league][snap.key] = val;
			});

			setData({
				loading: false,
				teams: teams,
			});
		}, {
			onlyOnce:true,
		});

		return () => {
			// A callback that can invoked to remove a listener.
			unsubscribe();
		}
	}, []);

	if(data.loading) {
		return (
			<section className="page teams-page">
				<h1 className="page-title">Teams</h1>
				<div className="loading">LOADING</div>
			</section>
		);
	}

	return (
		<section className="page teams-page">
			<h1 className="page-title">Teams</h1>

			<Columns
				className="columns-3"
			>
				<Column>
					<Card
						title="NSL"
					>
						<TeamsList
							teams={ data.teams['NSL'] }
						/>
					</Card>
				</Column>
				<Column>
					<Card
						title="NSA"
					>
						<TeamsList
							teams={ data.teams['NSA'] }
						/>
					</Card>
				</Column>
				<Column>
					<Card
						title="Lower League"
					>
						<TeamsList
							teams={ data.teams['Lower League'] }
						/>
					</Card>
				</Column>
			</Columns>
		</section>
	);
};

// Never re-render
export default React.memo(TeamsPage, (prevProps, nextProps) => {
	return false;
});