import React from 'react';

const TeamFormation = (props) => {

	const { formation, selected, save } = { ...props }

	const onClick = ( !save ? null : (e) => {
		e.preventDefault();
		save(formation);
	} );

	return (
		<button
			type="button"
			className="team-formation"
			data-selected={ selected }
			disabled={ !save }
			onClick={ onClick}>
			<span>{ formation }</span>
		</button>
	);

};

// Should component update
export default React.memo(TeamFormation, (prevProps, nextProps) => {
	return prevProps.selected === nextProps.selected;
});