import React, { useEffect, useReducer } from 'react';

import '../../assets/scss/components/dice/RollButton.scss';

import { wait, SIMULATED_WAIT_TIME, invertColor, getUserIdFromTeamSlug } from '../../utils/helpers';
import useIsMounted from '../../hooks/useIsMounted';
import styled from 'styled-components';
import { auth } from '../../services/Firebase';

const StyledWrapper = styled.div`
		button,
	 	.checkbox label:before {
			background-color: ${ props => props.colors.color1 ? props.colors.color1 : '#000' };
			color: ${ props => props.colors.color2 ? props.colors.color2 === props.colors.color1 ? invertColor(props.colors.color2) : props.colors.color2 : '#FFF' };
		}
		.checkbox label .checkmark path {
			fill: ${ props => props.colors.color2 ? props.colors.color2 === props.colors.color1 ? invertColor(props.colors.color2) : props.colors.color2 : '#FFF' };
		}	
	`;

const RollButton = (props) => {

	const { colors, slug } = { ...props };
	const isMounted = useIsMounted();

	// Get the User Logged in and the User from the Team slug
	const user = auth.currentUser;
	const userIdTeam = getUserIdFromTeamSlug(slug);

	const initialState = {
		disabled: ( !user || userIdTeam !== user.uid ),
		hidden: true,
	};

	const reducer = (state, action) => ({ ...state, ...action });
	const [state, setState] = useReducer(reducer, initialState);

	const handleClick = (e) => {
		e.preventDefault();

		setState({
			disabled: true,
		});

		props.makeRoll(state.hidden, slug);
	};

	useEffect(() => {

		async function waitForIt() {

			await wait(SIMULATED_WAIT_TIME);

			// The item may not be mounted anymore
			if(isMounted.current) {
				setState({
					disabled: false,
				});
			}
		}

		if(isMounted.current && state.disabled && userIdTeam === user.uid) {
			waitForIt();
		}

	}, [state, isMounted, userIdTeam, user]);

	const cbId = `hidden-roll-${slug}`;

	return (
		<StyledWrapper
			className="roll-btn"
			colors={ colors }
		>
			<button type="button" onClick={ handleClick } disabled={ state.disabled }>
				<span>Roll the Dice</span>
			</button>
			<div className="checkbox">
				<input
					id={ cbId }
					type="checkbox"
					defaultChecked={ true }
					disabled={ state.disabled }
					onChange={ (e) => {
						setState({
							hidden: e.target.checked,
						});
					}}
				/>
				<label
					htmlFor={ cbId }
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 847 847"
						fillRule="evenodd"
						clipRule="evenodd"
						shapeRendering="geometricPrecision"
						textRendering="geometricPrecision"
						imageRendering="optimizeQuality"
						className="checkmark"
					>
						<path d="M299 522l396-396c18-18 47-18 65 0l51 51c18 18 18 47 0 64L332 721c-18 18-47 18-65 0L35 489c-17-17-17-46 0-64l52-51c17-18 46-18 64 0l148 148z" />
					</svg>
					<span className="copy">Secret roll (only visible for GM)</span>
				</label>
			</div>
		</StyledWrapper>
	)
};

export default React.memo(RollButton, (prevProps, nextProps) => {
	return prevProps.colors.color1 === nextProps.colors.color1
		&& prevProps.colors.color2 === nextProps.colors.color2;
});