import React, { useState, useEffect } from 'react';
import {Routes, Route, useLocation} from "react-router-dom";

import './assets/scss/App.scss';

import { onAuthStateChange } from './services/Firebase';

import Header from './components/layout/Header';
import Footer from './components/layout/Footer';

import LoginPage from './components/authentication/LoginPage';

import DicePage from './components/dice/DicePage';

import TeamsPage from './components/teams/TeamsPage';
import TeamPage from './components/team/TeamPage';

import TablesPage from './components/tables/TablesPage';

import MatchCentrePage from './components/match-centre/MatchCentrePage';
import MatchCentrePage2 from './components/match-centre/MatchCentrePage2';

import KitDesigner from './components/dev/kit-designer/KitDesigner';
import TodoPage from './components/dev/todo/Todo';

import ArchiveFixturesPage from './components/archive/fixtures/ArchiveFixturesPage';
import MatchCentre from './components/archive/fixtures/MatchCentre';

const App = () => {

	// State
	const [user, setUser] = useState({
		loading: true,
		user: null,
	});

	const userIsLoggedIn = (user) => {
		setUser({
			loading: false,
			user: user,
		});
	};

	const location = useLocation();
	useEffect(() => {
		// This effect will run every time the location changes
		document.body.classList.remove('js-primary-menu-revealed');

		// If you need to do some cleanup, you can return a function
		return () => {
			// Cleanup code here if needed
		};
	}, [location]); // <-- Dependency on location ensures effect runs on location changes

	// Not working in Firebase 9?
	useEffect(() => {
		const unsubscribe = onAuthStateChange(userIsLoggedIn);
		return () => {
			//console.log('App.onAuthStateChanged', unsubscribe);
			unsubscribe();
		};
	}, []);

	if(user.loading) {
		return (
			<section className="smrpg-dice-app">
				<div id="canvas">
					<div id="the-body">
						<div className="page" />
					</div>
				</div>
			</section>
		);
	}

	if(!user.user) {
		return (
			<section className="smrpg-dice-app">
				<div id="canvas">
					<Header />
					<div id="the-body">
						<LoginPage />
					</div>
					<Footer />
				</div>
			</section>
		);
	}

	return (
			<section className="smrpg-dice-app">
				<div id="canvas">
					<Header />

					<div id="the-body">
						<Routes>
							<Route index element={<DicePage />} />
							<Route path="teams/:slug" element={<TeamPage />} />
							<Route path="teams" element={<TeamsPage />} />
							<Route path="tables" element={<TablesPage />} />
							<Route path="match-centre" element={<MatchCentrePage />} />
							<Route path="match-centre-2" element={<MatchCentrePage2 />} />
							<Route path="archive/fixtures/:match_id" element={<MatchCentre />} />
							<Route path="archive/fixtures" element={<ArchiveFixturesPage />} />
							<Route path="dev/kit-designer" element={<KitDesigner />} />
							<Route path="dev/todo" element={<TodoPage />} />
						</Routes>
					</div>

					<Footer />
				</div>
			</section>
	);
};

export default App;