import '../../assets/scss/components/layout/Columns.scss';

const Columns = (props) => {

	const { id, className } = { ...props };

	const attr = {
		className : `columns ${ className ? className : ''}`,
	}
	if(id) {
		attr['id'] = id;
	}

	return (
		<section { ...attr }>
			{ props.children }
		</section>
	);
};

export default Columns;