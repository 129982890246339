import Player from './Player';
import { sortSquadBy } from '../../utils/helpers';

import '../../assets/scss/components/team/Squad.scss';

const Squad = (props) => {

	const players = sortSquadBy(props.players);

	return (
		<div className="squad">
			<div className="players-header">
				<div className="shirt">#</div>
				<div className="name">Name</div>
				<div className="age">Age</div>
				<div className="nationality">Nat.</div>
				<div className="positions">Pos.</div>
			</div>
			<div className="players-body">
				{
					Object.keys(players).map((key) => {

						return <Player
							key={ key }
							data={ players[key] }
						/>

					})
				}
			</div>
		</div>
	);
};

export default Squad;