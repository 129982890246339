import React from 'react';

import { getNameWithInitials } from '../../utils/helpers';

const PlayerInfo = (props) => {

	const { name, number/*, position*/ }  = { ...props }
	const displayName = ( name.length > 9 ? getNameWithInitials(name) : name );

	return (
		<div className="player-info">
			<div className="name">
				<div className="display-name"><span className="number">{ number }</span> { displayName }</div>
			</div>
		</div>
	);
};

// <div className="position">({ position })</div>

// Should component update
export default React.memo(PlayerInfo, (prevProps, nextProps) => {
	return prevProps.name === nextProps.name
		&& prevProps.number === nextProps.number
		&& prevProps.position === nextProps.position;
});