import React from "react";

import '../../assets/scss/components/layout/Footer.scss';

import packageJson from '../../../package.json';

const Footer = () => {
	return (
		<footer id="the-footer" className="the-footer">
			<div className="footer-inner">
				<div className="version-app">SMRPG App // version <strong>{ packageJson.version }</strong></div>
			</div>
		</footer>
	);
}

// Never re-render
export default React.memo(Footer, (prevProps, nextProps) => {
	return true;
});