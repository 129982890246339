import React from 'react';

import '../../assets/scss/components/match-centre/Score.scss';

const Score = (props) => {

	const { score } = { ...props }

	return (
		<div className="score">
			<span className="goal">{ score.home }</span> - <span className="goal">{ score.away }</span>
		</div>
	);

};

// Should component update
export default React.memo(Score, (prevProps, nextProps) => {
	return prevProps.score.home === nextProps.score.home && prevProps.score.away === nextProps.score.away;
});