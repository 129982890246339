import React, { useEffect, useState } from 'react';

import { db } from '../../../services/Firebase';

import Fixture from './Fixture';
import {onValue, orderByChild, query, ref} from "firebase/database";

const ArchiveFixturesPage = () => {

	const [data, setData] = useState({
		loading: true,
		fixtures: {},
	});

	// Get Teams on "componentDidMount" and remove eventlistener on componentDidUnmount
	useEffect(() => {

		const dbRef = ref(db, "archive/fixtures/season12/");
		const queryConstraints = [orderByChild('category')];
		const queryFixtures = query(dbRef, ...queryConstraints);

		const unsubscribe = onValue(queryFixtures, (snapshot) => {

			const fixtures = {}

			snapshot.forEach((snap) => {
				fixtures[snap.key] = snap.val();
			});

			setData({
				loading: false,
				fixtures,
			});
		}, {
			onlyOnce: true,
		});

		return () => {
			// A callback that can invoked to remove a listener.
			unsubscribe();
		}

	}, []);

	if(data.loading) {
		return (
			<section className="page archive-fixtures-page">
				<h1 className="page-title">Archive</h1>
				<div className="loading">Searching the archives...</div>
			</section>
		);
	}

	return (
		<section className="page archive-fixtures-page">
			<h1 className="page-title">Archive</h1>
			<p>Work in progress...</p>

			<div className="fixtures">
				<div className="fixture-list">
				{
					Object.keys(data.fixtures).map((key) => {

						const report = data.fixtures[key].report;

						return <Fixture
							key={ key }
							gameInfo={ report.gameinfo.data }
							homeTeam={ {
								name: report.teams.home.name,
								goals: report.score.data.home,
							} }
							awayTeam={ {
								name: report.teams.away.name,
								goals: report.score.data.away,
							} }
						/>

					})
				}
				</div>
			</div>
		</section>
	);


}

export default ArchiveFixturesPage;