import {auth} from '../services/Firebase';

import TeamLogoFCBoston from '../assets/images/TeamLogoFCBoston.svg';
import TeamLogoWolverines from '../assets/images/TeamLogoWolverines.svg';
import TeamLogoGameMaster from '../assets/images/TeamLogoGameMaster.svg';

const UID_GAME_MASTER = 'xBaHFAABq9ebkno05tCtIiivbx23';
const UID_FC_BOSTON = 'dhZvruXOhITT9vYtxRCGtqbxgmn2';
const UID_WOLVERINES = 'u1LvuRP2DCbT2fupBCcrSclEzw82';
const UID_SNUKES = 's6BsMBJ76YNlS14hYjRvA2d47LI2';

export function getTeamFromUser(user) {

	const team = {
		name: null,
		id: null,
		logo: null,
	};

	const uid = ( user && user.uid ? user.uid : user );

	switch(uid) {
		case UID_FC_BOSTON:
			team.name = 'FC Boston';
			team.id = 'fc-boston';
			team.logo = TeamLogoFCBoston;
			break;
		case UID_WOLVERINES:
			team.name = 'Wolverines';
			team.id = 'wichita-wolverines';
			team.logo = TeamLogoWolverines;
			break;
		case UID_GAME_MASTER:
			team.name = 'Game Master';
			team.id = 'game-master';
			team.logo = TeamLogoGameMaster;
			break;
		case UID_SNUKES:
			team.name = 'Allan (Guest user)';
			team.id = 'allan';
			team.logo = TeamLogoGameMaster;
			break;
		default:
			break;
	}
	return team;
}

export function isGameMaster() {

	const user = auth.currentUser;
	if(user) {
		return user.uid === UID_GAME_MASTER;
	}
	return false;
}
export function isHumanTeam(slug) {
	const userId = getUserIdFromTeamSlug(slug);
	return (userId === UID_FC_BOSTON || userId === UID_WOLVERINES );
}

// Hardcoded
export function getUserIdFromTeamSlug(slug) {

	switch(slug) {
		case 'fc-boston':
			return UID_FC_BOSTON;

		case 'wichita-wolverines':
			return UID_WOLVERINES;

		case 'allan':
			return UID_SNUKES;

		default:
			return UID_GAME_MASTER;
	}
}

export function getRandomRoll(min= 1, max= 101) {
	return Math.floor(Math.random() * (max - min) + min);
}

export function getLatestRollForUser(rolls, uid) {
	return rolls.find((roll) => {
		return roll.uid === uid;
	});
}


export function leadingZero(num) {
	if(num >=0 && num <=9) {
		return `0${num}`;
	}
	return num.toString();
}
export function numberWithOrdinal(n) {
	const s = ["th", "st", "nd", "rd"];
	const v = n % 100;
	return n + (s[(v - 20) % 10] || s[v] || s[0]);
}
export function number1000Seperator(n) {
	return n.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

// Fixture Helpers
export function sortFixturesBy(fixtures, property="order") {
	return Object.values(fixtures).sort((a,b) => {
		return a[property] > b[property] ? 1 : -1;
	});
}
export function getLatestResults(fixtures, number= 5) {

	const orderedFixtures = sortFixturesBy(fixtures, "order");

	return orderedFixtures
		.reverse() // Look from the end...
		.filter((fixture) => { // remove all fixtures without a result
			return fixture.result.wdl !== '';
		})
		.slice(0, number);
}
export function getFormFromFixtures(fixtures, number= 5) {

	const latestFixturesWithResults = getLatestResults(fixtures, number);

	return latestFixturesWithResults.map((fixture) => {
		return fixture.result.wdl;
	});
}

// Players Helpers
export function sortSquadBy(squad, property="positions", property2="order") {
	return Object.values(squad).sort((a,b) => {

		if(property && property2) {

			// If same order, sort by shirt number as the second option
			if(a[property][property2] === b[property][property2]) {
				return a['shirt'] > b['shirt'] ? 1 : -1;
			}

			return a[property][property2] > b[property][property2] ? 1 : -1;
		}
		return a[property] > b[property] ? 1 : -1;
	});
}


export function wait(ms = 0) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export const SIMULATED_WAIT_TIME = 2000;

// Fun with Flags
export function getFlagFromCountry(country) {

	const c = country.toLowerCase();

	if(c === 'czech r./usa') { // Barry Koller
		return `/assets/flags/rectangular/czech-republic.svg`;
	}
	if(c === 'zaire') {
		return `/assets/flags/rectangular/democratic-republic-of-congo.svg`;
	}

	return `/assets/flags/rectangular/${ c }.svg`;
}

// Colors
export function invertColor(hex) {
	if (hex.indexOf('#') === 0) {
		hex = hex.slice(1);
	}
	// convert 3-digit hex to 6-digits.
	if (hex.length === 3) {
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
	}
	if (hex.length !== 6) {
		throw new Error('Invalid HEX color.');
	}
	// invert color components
	const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
		g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
		b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
	// pad each with zeros and return
	return '#' + leadingZero(r) + leadingZero(g) + leadingZero(b);
}
/*
export function getHSP(color) {

	// Variables for red, green, blue values
	let r, g, b, hsp;

	// Check the format of the color, HEX or RGB?
	if (color.match(/^rgb/)) {

		// If RGB --> store the red, green, blue values in separate variables
		color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

		r = color[1];
		g = color[2];
		b = color[3];
	}
	else {

		// If hex --> Convert it to RGB: http://gist.github.com/983661
		color = +("0x" + color.slice(1).replace(
			color.length < 5 && /./g, '$&$&'));

		r = color >> 16;
		g = color >> 8 & 255;
		b = color & 255;
	}

	// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
	hsp = Math.sqrt(
		0.299 * (r * r) +
		0.587 * (g * g) +
		0.114 * (b * b)
	);

	return hsp;
}
*/

//
// Strings
export function getNameWithInitials(name) {

	// Break name into array (removing all chars inside () )
	const arrNames = name.replace(/ *\([^)]*\) */g, "").split(" ");
	if(arrNames.length <= 1) {
		return name;
	}

	const lastName = arrNames.pop();

	return arrNames.map((n)=> {
		// Make sure we only allow Chars from a-Å
		const string = n.replace(/[^A-Za-zæøåÆØÅ]/gi, '');
		return string[0];
	}).join(".") + '. ' + lastName;
}

//
// Translations
export function translateCompetitionName(name) {
	switch(name) {
		case 'MLS_Regular_Season':
			return 'League game';

		case 'MLS_Playoff':
			return 'Playoff';

		default:
			return name;
	}
}