import React, { useState } from 'react';

import { useFormInput } from '../hooks/useFormInput';
import { signin } from '../../services/Firebase';

const LoginPage = () => {

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Simple (very simple) validation
		if(!email.value && !password.value) {
			setError('Email og/eller password er ikke udfyldt');
			return;
		}

		setError(null);

		try {
			await signin(email.value, password.value);
		} catch (error) {
			setError(error.message);
		}
	}

	const [error, setError] = useState(null);
	const email = useFormInput('');
	const password = useFormInput('');

	return (
		<div className="page login-page">
			<h1 className="heading">SMRPG // Login</h1>
			<form autoComplete="on" onSubmit={ handleSubmit }>

				<input
					{...email}
					className="input-field"
					placeholder="Email"
					name="email"
					type="email"
				/>
				<input
					{...password}
					className="input-field"
					placeholder="Password"
					name="password"
					type="password"
				/>

				<button className="btn">Login</button>

				{ error ? <p className="errors">{ error }</p> : null }

			</form>
		</div>
	);

};

export default LoginPage;