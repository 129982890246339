import React, { useRef, useState, useEffect } from 'react';

import '../../assets/scss/components/tactics/PlayerPositions.scss';

import Player from './Player';
import { auth, db } from '../../services/Firebase';
import _ from 'lodash';
import { getUserIdFromTeamSlug } from '../../utils/helpers';
import { getPlayerPositionsInFormation } from '../../utils/formations';
import {ref, update} from "firebase/database";

const PlayerPositions = (props) => {

	const { slug, players, kitId, formation, playerPositions, reverse } = { ...props }

	// If the User and team's User doesn't match, then we can't drag the player
	const userId = getUserIdFromTeamSlug(slug); // userId who has access to this team
	const user = auth.currentUser; // Get the User logged in
	const allowEdits = ( userId === user.uid );

	const tacticBoard = useRef(); // Used to get bounding rect

	const [data, setData] = useState({
		isDragging: false,
		playerGettingDragged: null, // Index of player getting dragged
		positions: {}, // All the positions
	});

	useEffect(() => {

		setData({
			isDragging: false,
			playerGettingDragged: null, // Index of player getting dragged
			positions: playerPositions,
		});
	}, [playerPositions]);

	useEffect(() => {
		if(data.isDragging) {
			document.ontouchmove = function(e) {
				e.preventDefault();
			};
		}
		else {
			document.ontouchmove = null;
		}

		return () => {
			document.ontouchmove = null;
		}
	}, [data.isDragging]);

	const playerPositionsByFormation = getPlayerPositionsInFormation(formation);

	const onPlayerSelected = (playerIndex) => {

		setData({
			...data,
			isDragging: true,
			playerGettingDragged: playerIndex,
		});
	}
	const onPlayerDeSelected = () => {

		setData({
			...data,
			isDragging: false,
		});
		savePlayerPosition();
	}
	const onDragging = (e) => {

		e.persist();

		if(data.isDragging) {

			const bounds = tacticBoard.current.getBoundingClientRect();

			let clientX = e.clientX;
			let clientY = e.clientY;

			if(e.touches) {

				if(e.touches.length > 0) {
					clientX = e.touches[0].clientX;
					clientY = e.touches[0].clientY;
				}
			}

			const x = ( clientX - bounds.left < 0
					? 0
					: clientX - bounds.left > bounds.width
						? bounds.width
						: clientX - bounds.left
			);

			const y = ( clientY - bounds.top < 0
					? 0
					: clientY - bounds.top > bounds.height
						? bounds.height
						: clientY - bounds.top
			);

			const positions = _.cloneDeep(data.positions);
			positions[`player${data.playerGettingDragged}`] = {
				x : ( reverse ? 100 - x/bounds.width * 100 : x/bounds.width * 100 ),
				y : ( reverse ? 100 - y/bounds.height * 100 : y/bounds.height * 100 ),
			}
			setData({
				...data,
				positions,
			});
		}
	}
	const onCancelDragging = (e) => {
		e.persist();
		if(data.isDragging) {
			setData({
				...data,
				isDragging: false,
			});
		}
	};

	const savePlayerPosition = () => {

		update(ref(db, `teams/${slug}/match-centre/tactic-board/formation/players/`), data.positions)
			.then(_ => {
			//console.log('savePlayerPosition SUCCESS', data.positions);
			})
			.catch((error) => {
				// The write failed...
				//console.log('savePlayerPosition ERROR', error);
			});
	}

	return (
		<div
			className="player-positions"
			data-is-dragging={ data.isDragging }

			onMouseMove={ allowEdits ? onDragging : null }
			onTouchMove={ allowEdits ? onDragging : null }

			onMouseLeave={ allowEdits ? onCancelDragging : null }
			onTouchEnd={ allowEdits ? onCancelDragging : null }
			onTouchCancel={ allowEdits ? onCancelDragging : null }

			ref={ tacticBoard }
		>
			{
				Object.keys(players).map((key, index) => {
					return <Player
						key={ key }
						index={ index }
						data={ players[key] }
						slug={ slug }
						kitId={ kitId }
						reverse={ reverse }
						draggable={ allowEdits && players[key].position !== 'GK' }
						selectPlayer={ allowEdits ? onPlayerSelected : null }
						deSelectPlayer={ allowEdits ? onPlayerDeSelected : null }
						position={ data.positions[`player${index}`] ? data.positions[`player${index}`] : playerPositionsByFormation[index] }
					/>

				})
			}
		</div>
	);
};

export default PlayerPositions;