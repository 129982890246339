import '../../assets/scss/components/team/IconAndValue.scss';

const IconAndValue = (props) => {

	const { id, className, icon, label, name } = { ...props };

	const attr = {
		className : `icon-and-value ${ className ? className : ''}`,
	}
	if(id) {
		attr['id'] = id;
	}

	return (
		<div { ...attr }>
			<div className="icon">
				<img src={ icon } alt={ label } />
			</div>
			<h6 className="name">{ name }</h6>
			<div className="label">{ label }</div>
		</div>
	);

}

export default IconAndValue;