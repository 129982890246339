import '../../assets/scss/components/team/Form.scss';

const Form = (props) => {

	let { results, label } = { ...props };

	if(!results) {
		results = [];
	}

	return (
		<div className="form">
			{(() => {
				if(label) {
					return (
						<div className="label">{ label }</div>
					);
				}
			})()}
			{(() => {

				if(results.length > 0) {

					return results.map((result,index) => {

						const key = `form${index}`;
						const className = `result result-${result.toLowerCase()}`;

						return (
							<div
								key={ key }
								className={ className }
								><span>{result}</span>
							</div>
						);
					})
				}
				else {
					return <div className="result no-result">N/A</div>
				}
			})()}
		</div>
	);

};

export default Form;