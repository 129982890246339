import React from 'react';

import '../../assets/scss/components/tactics/TeamFormations.scss';

import TeamFormation from './TeamFormation';
import { getAvailableFormations } from '../../utils/formations';
import { getUserIdFromTeamSlug } from '../../utils/helpers';
import { auth } from '../../services/Firebase';

const TeamFormations = (props) => {

	const { slug, formation, saveTeamFormation, hidden } = { ...props };

	// If the User and team's User doesn't match, then we can't drag the player
	const userId = getUserIdFromTeamSlug(slug); // userId who has access to this team
	const user = auth.currentUser; // Get the User logged in
	const allowEdits = ( userId === user.uid );

	const availableFormations = getAvailableFormations();

	return (
		<div className="team-formations">
			{
				availableFormations.map((f) => {

					return <TeamFormation
						key={ f }
						formation={ f }
						selected={ f === formation && !hidden }
						save={ allowEdits ? saveTeamFormation : null }
					/>
				})
			}
		</div>
	);
};

// Should component update
export default React.memo(TeamFormations, (prevProps, nextProps) => {
	return prevProps.formation === nextProps.formation && prevProps.hidden === nextProps.hidden && prevProps.slug === nextProps.slug;
});