import React from "react";

import '../../assets/scss/components/dice/DicePage.scss';

import { ref, remove } from "firebase/database";
import { auth, db } from '../../services/Firebase';

import Card from '../layout/Card';
import Columns from '../layout/Columns';
import Column from '../layout/Column';
import TeamDice from './TeamDice';

const DicePage = () => {

	const deleteAllRolls = (e) => {
		e.preventDefault();

		const user = auth.currentUser;
		if(user) {
			if(window.confirm('This will delete all saved rolls...')) {

				remove(ref(db, "dice/rolls/"))
					.then(_ => {
						//console.log('Dice Rolls Removed SUCCESS');
					}).catch((error) => {
						//console.log('Dice Rolls Removed ERROR', error);
					});
			}
		}
		else {
			alert('You need to login before deleting all rolls');
		}
	};

	const limit = 20;

	return (
		<div className="page dice-page">

			<h1 className="page-title">Dice</h1>
			<p style={{position: 'relative'}}>
				The { limit } latest rolls for GM and each player...
				<button id="delete-all-rolls" type="button" onClick={ deleteAllRolls }><span>Delete all rolls</span></button>
			</p>

			<Columns>
				<Column>
					<Card
						title="Wichita Wolverines"
					>
						<TeamDice
							slug="wichita-wolverines"
							name="Wichita Wolverines"
							limit={ limit }
						/>
					</Card>
				</Column>
				<Column>
					<Card
						title="Game Master"
					>
						<TeamDice
							slug="game-master"
							name="Game Master"
							limit={ limit }
						/>
					</Card>
				</Column>
				<Column>
					<Card
						title="FC Boston"
					>
						<TeamDice
							slug="fc-boston"
							name="FC Boston"
							limit={ limit }
						/>
					</Card>
				</Column>

			</Columns>
		</div>
	);
};

export default DicePage;