import React from 'react';

import { getFlagFromCountry } from '../../utils/helpers';

const Player = (props) => {

	const { age, name, nationality, shirt, positions } = { ...props.data }

	const flag = getFlagFromCountry(nationality);

	return (
		<div className="player">
			<div className="shirt">{ shirt }</div>
			<div className="name">{ name }</div>
			<div className="age">{ age }</div>
			<div className="nationality"><img src={ flag } alt={ nationality } title={ nationality } /></div>
			<div className="positions">{ positions.position }{ positions.position !== 'GK' ? ` (${ positions.side })` : null } </div>
		</div>
	);
};

// Never re-render
export default React.memo(Player, (prevProps, nextProps) => {
	return prevProps.data.age === nextProps.data.age
		&& prevProps.data.name === nextProps.data.name
		&& prevProps.data.nationality === nextProps.data.nationality
		&& prevProps.data.shirt === nextProps.data.shirt
		&& prevProps.data.positions.side === nextProps.data.positions.side
		&& prevProps.data.positions.position === nextProps.data.positions.position;
});