import React from "react";

import icon from '../../../assets/images/EventWhistle.svg';

const Halftime = (props) => {

	const score = props.score;

	return (
		<div className="event halftime milestone">
			<div className="icon">
				<img src={ icon } alt="PffT Pfft Pffffffffffft"/>
			</div>
			<div className="info">Halftime<br />{ score.home } - { score.away }</div>
		</div>
	);
};

// Should component update
export default React.memo(Halftime, (prevProps, nextProps) => {
	return prevProps.score.home === nextProps.score.home && prevProps.score.away === nextProps.score.away;
});