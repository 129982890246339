import React from 'react';

import '../../assets/scss/components/match-centre/Events.scss';

import Fulltime from './events/Fulltime';
import Kickoff from './events/Kickoff';
import Missed from './events/Missed';
import Injury from './events/Injury';
import Yellow from './events/Yellow';
import Red from './events/Red';
import Substitution from './events/Substitution';
import Goal from './events/Goal';
import Halftime from './events/Halftime';
import NoEvent from './events/NoEvent';
import MissedPenalty from './events/MissedPenalty';
import MOM from './events/MOM';

const Events = (props) => {

	const events = props.events;
	const { nameHomeTeam } = { ...props };

	// Keeping track of the score so we can output the score at the time of the event
	const score = {
		home: 0,
		away: 0,
	};

	return (
		<div className="events">
			<div className="timeline">
				{
					Object.keys(events).map((key) => {

						const type = events[key].type;
						const isHomeTeam = events[key].team === nameHomeTeam;
						const currentScore = { ...score };

						// Update the current score
						if(type === 'GOAL') {
							if(isHomeTeam) {
								score.home++;
								currentScore.home++;
							}
							else {
								score.away++;
								currentScore.away++;
							}
						}

						const eventProps = {
							key,
							data: events[key],
							isHomeTeam: isHomeTeam,
							score: currentScore,
						};

						switch(type) {
							case 'KICKOFF':
								return <Kickoff {...eventProps} />;

							case 'GOAL':
								return <Goal {...eventProps} />;

							case 'YELLOW':
								return <Yellow {...eventProps} />;

							case 'INJURY':
								return <Injury {...eventProps} />;

							case 'RED':
								return <Red {...eventProps} />;

							case 'MISSED':
								return <Missed {...eventProps} />;

							case 'MISSED_PENALTY':
								return <MissedPenalty {...eventProps} />;

							case 'MOM':
								return <MOM {...eventProps} />;

							case 'SUBSTITUTION':
								return <Substitution {...eventProps} />;

							case 'HALFTIME':
								return <Halftime {...eventProps} />;

							case 'FULLTIME':
								return <Fulltime {...eventProps} />;

							default:
								return <NoEvent {...eventProps} />;
						}
					})
				}
			</div>

		</div>
	);
};

export default Events;


/**
 DEPRECATED...
 The events are always re-rendered with Lazly loading :/

 return (
		<div className="events">
			<div className="timeline">
				{
					Object.keys(events).map((key) => {

						const type = events[key].type;
						const isHomeTeam = events[key].team === nameHomeTeam;
						const currentScore = { ...score };

						let EventComponent;

						switch(type) {
							case 'KICKOFF':
								EventComponent = React.lazy(() => import('./events/Kickoff'));
								break;

							case 'GOAL':
								EventComponent = React.lazy(() => import('./events/Goal'));

								// Update the current score
								if(isHomeTeam) {
									score.home++;
									currentScore.home++;
								}
								else {
									score.away++;
									currentScore.away++;
								}
								break;

							case 'YELLOW':
								EventComponent = React.lazy(() => import('./events/Yellow'));
								break;

							case 'INJURY':
								EventComponent = React.lazy(() => import('./events/Injury'));
								break;

							case 'RED':
								EventComponent = React.lazy(() => import('./events/Red'));
								break;

							case 'MISSED':
								EventComponent = React.lazy(() => import('./events/Missed'));
								break;

							case 'SUBSTITUTION':
								EventComponent = React.lazy(() => import('./events/Substitution'));
								break;

							case 'HALFTIME':
								EventComponent = React.lazy(() => import('./events/Halftime'));
								break;

							case 'FULLTIME':
								EventComponent = React.lazy(() => import('./events/Fulltime'));
								break;

							default:
								EventComponent = React.lazy(() => import('./events/NoEvent'));
								break;
						}

						return (
						<Suspense
							key={ key }
							fallback={<div className="event-loading">...</div>}>
							<EventComponent
								data={ events[key] }
								isHomeTeam={ isHomeTeam }
								score={ currentScore }
							/>
						</Suspense>
						);
					})
				}
			</div>

		</div>
	);
 */