import React from "react";

import '../../assets/scss/components/tables/TablesPage.scss';

import iconRotatePhone from '../../assets/images/RotatePhone.svg';

import Table from './Table';
import Card from '../layout/Card';

const TablesPage = () => {

	//console.log('TablesPage.render()');

	return (
		<div className="page tables-page">
			<h1 className="page-title">League Tables</h1>
			<Card
				title="NSL"
				icon={ iconRotatePhone }
				className="card-table"
			>
				<Table
					league="nsl"
				/>
			</Card>
			<Card
				title="NSA"
				icon={ iconRotatePhone }
				className="card-table"
			>
				<Table
					league="nsa"
				/>
			</Card>
		</div>
	);

};

// Never re-render
export default React.memo(TablesPage, (prevProps, nextProps) => {

	//if(prevProps.team !== undefined && nextProps.team !== undefined) {
	//	return prevProps.team.id === nextProps.team.id;
	//}

	return false;
});