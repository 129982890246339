import React, { useState, useEffect, useRef } from 'react';

import TeamKit from '../match-centre/TeamKit';
import PlayerInfo from './PlayerInfo';

function usePrevious(value) {
	// The ref object is a generic container whose current property is mutable ...
	// ... and can hold any value, similar to an instance property on a class
	const ref = useRef();

	// Store current value in ref
	useEffect(() => {
		ref.current = value;
	}, [value]); // Only re-run if value changes

	// Return previous value (happens before update in useEffect above)
	return ref.current;
}

const Player = React.memo((props) => {

	const { slug, kitId, data, draggable, selectPlayer, deSelectPlayer, index, position, reverse } = { ...props }
	const { name, number }  = { ...data }

	const [ allWhite, setAllWhite ] = useState(false);

	const prevPosition = usePrevious(position);

	const className = `player ${ data.position === 'GK' ? `goalkeeper` : '' }`;

	let style = {};
	if(position) {
		style = {
			zIndex: 999,
			left: `${ reverse ? 100 - position.x : position.x }%`,
			top: `${ reverse ? 100 - position.y : position.y }%`,
		};
	}
	else if(prevPosition) {
		style = {
			left: `${ reverse ? 100 - prevPosition.x : prevPosition.x }%`,
			top: `${ reverse ? 100 - prevPosition.y : prevPosition.y }%`,
		};
	}

	return (
		<div
			className={className}
			data-draggable={ draggable }
			data-reverse={ reverse }
			onMouseDown={ draggable ? () => { selectPlayer(index) } : null }
			onTouchStart={ draggable ? () => { selectPlayer(index) } : null }
			onMouseUp={ draggable ? () => { deSelectPlayer() } : null }
			onTouchEnd={ draggable ? () => { deSelectPlayer() } : null }
			style={{ ...style }}
		>
			<PlayerInfo
				number={ number }
				name={ name }
				position={ data.position }
			/>
			<div className="team-kit-wrapper" data-all-white={ allWhite }>
				<TeamKit
					slug={ slug }
					kitId={ kitId }
					goalkeeper={ data.position === 'GK' }
					callback={ (kit, goalkeeper) => {

						if(!kit.shirt_1) {
							return;
						}

						if((kit.shirt_1.toLowerCase() === '#fff' && kit.shirt_2.toLowerCase() === '#fff') || (goalkeeper && kit.goalkeeper_1.toLowerCase() === '#fff')) {
							setAllWhite(true);
						}
					}}
				/>
			</div>
		</div>
	);

});

export default Player;