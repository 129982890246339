import React from 'react';

import '../../assets/scss/components/layout/Card.scss';

const Card = (props) => {

	const { id, className, title, icon } = { ...props };

	const attr = {
		className : `card ${ className ? className : ''}`,
	}
	if(id) {
		attr['id'] = id;
	}

	return (
		<div { ...attr }>
			{(() => {
				if(title || icon) {
					return (
						<div className="card-title">
							{ title ? <h3>{ title }</h3> : null }
							{ icon ? <img src={icon} alt="" className="icon" /> : null }
						</div>
					);
				}
				else {
					return <div className="no-card-title-spacer" />;
				}
			})()}
			{ props.children }
		</div>
	);
};

export default Card;