import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { db } from '../../../services/Firebase';
import { onValue, query, ref} from "firebase/database";

import '../../../assets/scss/components/match-centre/MatchCentrePage.scss';
import '../../../assets/scss/components/archive/fixtures/MatchCentre.scss';

import Columns from '../../layout/Columns';
import Column from '../../layout/Column';
import Card from '../../layout/Card';

import Score from '../../match-centre/Score';
import GameInfo from '../../match-centre/GameInfo';
import Events from '../../match-centre/Events';

import TeamKit from '../../match-centre/TeamKit';

import TeamInfo from '../../match-centre/TeamInfo';
import Players from '../../match-centre/Players';


const MatchCentre = () => {

	const { match_id } = useParams();

	const [data, setData] = useState({
		loading: true,
		matchCentre: {},
	});

	// Get Teams on "componentDidMount" and remove eventlistener on componentDidUnmount
	useEffect(() => {

		const dbRef = ref(db, `archive/fixtures/season12/${match_id}`);
		const queryFixture = query(dbRef);

		const unsubscribe = onValue(queryFixture, (snapshot) => {

			const matchCentre = {}

			snapshot.forEach((snap) => {
				matchCentre[snap.key] = snap.val();
			});

			setData({
				loading: false,
				matchCentre,
			});
		}, {
			onlyOnce: true,
		});

		return () => {
			// A callback that can invoked to remove a listener.
			unsubscribe();
		}

	}, [match_id]);

	if(data.loading) {
		return (
			<div className="page archive-match-centre-page">
				<h1 className="page-title">Match Centre</h1>
				<div className="loading">LOADING</div>
			</div>
		);
	}

	const matchcentre = data.matchCentre.report;
	const homeTeam = matchcentre.teams.home;
	const awayTeam = matchcentre.teams.away;

	return (
		<div className="page archive-match-centre-page match-centre-page">

			<h1 className="page-title">From the archive</h1>

			<Columns>
				<Column>
					<Card
						title={ homeTeam.name }
					>
						<TeamKit
							slug={ homeTeam.slug }
							kitId={ homeTeam.kitId }
						/>
						<TeamInfo
							teamInfo={ homeTeam }
						/>
						<Players
							players={ matchcentre.players__home }
						/>
					</Card>
				</Column>
				<Column>
					<Card>
						<Score
							score={ matchcentre.score.data }
						/>
						<GameInfo
							data={ matchcentre.gameinfo.data }
						/>
						<Events
							events={ matchcentre.events }
							nameHomeTeam={ homeTeam.name }
						/>
					</Card>
				</Column>
				<Column>
					<Card
						title={ awayTeam.name }
					>
						<TeamKit
							slug={ awayTeam.slug }
							kitId={ awayTeam.kitId }
						/>
						<TeamInfo
							teamInfo={ awayTeam }
						/>
						<Players
							players={ matchcentre.players__away }
						/>
					</Card>
				</Column>

			</Columns>

		</div>
	);

}

export default MatchCentre;