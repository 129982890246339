import React from 'react';

import IconAndValue from '../layout/IconAndValue';
import iconStadium from '../../assets/images/Stadium.svg';

import { number1000Seperator } from '../../utils/helpers';

const Stadium = (props) => {

	const { data } = { ...props };

	return (
		<IconAndValue
			label="Stadium"
			className="stadium"
			icon={ iconStadium }
			name={ `${data.name}\n${number1000Seperator(data.capacity) } capacity` }
		/>
	);
}

// Never re-render
export default React.memo(Stadium, (prevProps, nextProps) => {
	return prevProps.data.name === nextProps.data.name && prevProps.data.capacity === nextProps.data.capacity;
});