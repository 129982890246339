import React from "react";

const Player = (props) => {

	const { name, number, /*position,*/ technique }  = { ...props.data }

	return (
		<div className="player">
			<div className="shirt">{ number }</div>
			<div className="name">{ name }</div>
			<div className="technique">{ technique }</div>
		</div>
	);
};
// <div className="positions">{ position }</div>

// Should component update
export default React.memo(Player, (prevProps, nextProps) => {
	return prevProps.data.name === nextProps.data.name
		&& prevProps.data.number === nextProps.data.number
		&& prevProps.data.position === nextProps.data.position
		&& prevProps.data.technique === nextProps.data.technique;
});