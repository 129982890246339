import Player from './Player';

import '../../assets/scss/components/match-centre/Players.scss';

const Players = (props) => {

	const players = props.players;

	return (
		<div className="squad mc-players">
			<div className="players-header">
				<div className="shirt">#</div>
				<div className="name">Name</div>
				<div className="technique">Tech.</div>
			</div>
			<div className="players-body">
				{
					Object.keys(players).map((key) => {

						return <Player
							key={ key }
							data={ players[key] }
						/>

					})
				}
			</div>
		</div>
	);
};

// <div className="positions">Pos.</div>

export default Players;