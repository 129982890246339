import React from "react";

const TeamStat = (props) => {

	const { label, value, hideValue } = { ...props };

	const className = `stat stat-${ label.toLowerCase().replace(' ', '-') }`;

	return (
		<li className={ className }>
			<span className="value">{ ( hideValue ? '??' : value ) }</span>
			<span className="label">{ label }</span>
		</li>
	);

};

// Should component update
export default React.memo(TeamStat, (prevProps, nextProps) => {
	return prevProps.label === nextProps.label
		&& prevProps.value === nextProps.value
		&& prevProps.hideValue === nextProps.hideValue;
});