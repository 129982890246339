import React from 'react';

import IconAndValue from '../layout/IconAndValue';
import iconTactic from '../../assets/images/Tactic.svg';

const TacticPreferred = (props) => {

	const { data } = { ...props };

	return (
		<IconAndValue
			label={`Tactic`}
			className="tactic"
			icon={ iconTactic }
			name={ `Counter ${data.counter }\n${data.formation}` }
		/>
	);
}

// Never re-render
export default React.memo(TacticPreferred, (prevProps, nextProps) => {
	return prevProps.data.counter === nextProps.data.counter && prevProps.data.formation === nextProps.data.formation;
});