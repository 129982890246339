import React from "react";

import '../../assets/scss/components/layout/MenuSecondary.scss';
import Logout from '../authentication/Logout';

const MenuSecondary = () => {

	return (
		<nav className="menu-secondary">

			<div style={{ marginBottom: '1em', width: "100%", textAlign: "center" }}>More to come...</div>

			<Logout />
		</nav>
	);
};

// Never re-render
export default React.memo(MenuSecondary, (prevProps, nextProps) => {

	//if(prevProps.team !== undefined && nextProps.team !== undefined) {
	//	return prevProps.team.id === nextProps.team.id;
	//}

	return false;
});