import React, { useEffect, useState } from 'react';

import { db } from '../../services/Firebase';
import { onValue, query, ref } from "firebase/database";

import TableHeader from './TableHeader';
import TableTeam from './TableTeam';

import '../../assets/scss/components/tables/Table.scss';

const Table = (props) => {

	const { league } = { ...props };

	const [data, setData] = useState({
		loading: true,
		teams: {},
	});

	// Get Teams on "componentDidMount" and remove eventlistener on componentDidUnmount
	useEffect(() => {

		const dbRef = ref(db, `tables/${league}/`);
		const queryTable = query(dbRef);

		const unsubscribe = onValue(queryTable, (snapshot) => {

			const teams = {}
			snapshot.forEach((snap) => {
				teams[snap.key] = snap.val();
			});

			setData({
				loading: false,
				teams,
			});
		});

		return () => {
			// A callback that can invoked to remove a listener.
			unsubscribe();
		}
	}, [league]);

	//console.log('Table.render()', league);

	return (
		<div className="table">
			<TableHeader />

			{(() => {
				if(data.loading) {

					return [...Array(10)].map((_, i) => {
						return <TableTeam
							key={ i }
							data={{ position: i + 1 } }
						/>
					});
				}
				else {

					return Object.keys(data.teams).map((key) => {

						return <TableTeam
							key={ key }
							data={ data.teams[key] }
						/>
					});

				}
			})()}

		</div>
	);

};

export default Table;